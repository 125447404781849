import User from '@/models/User';

const METHOD_REGISTER = {
  create: 'registerCreate',
  update: 'registerUpdate',
}

export default {
  logoff(context){
    localStorage.removeItem('must_token');
    context.commit('setUser', {});
  },
  async getUser(context) {
    context.commit('loading', true, {root: true});

    return new Promise(function(resolve, reject){
      User.getInfoUser().then(function(response){
        let data = response.data.data;
        
        context.commit('setUser', data);
        context.commit('Wishlist/setWishlist', data.wishlists,{root: true});
        context.commit('loading', false, {root: true});

        resolve(data);
      })
      .catch(error => {
        if('response' in error){
          if('url' in error.response.data){            
            localStorage.removeItem('must_token');
            window.location.href = '/';
          }
        }
      });
    });
  },
  async login(context, params) {
    context.commit('loading', true, {root: true});

    return new Promise(function(resolve, reject){
      User.login(params).then(function(response){
        let data = response.data.data;
        console.log(params);
        
        context.commit('setUser', data);        
        context.commit('loading', false, {root: true});

        resolve(data);
      })
      .catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  async resetPass(context, params) {
    context.commit('loading', true, {root: true});

    return new Promise(function(resolve, reject){
      User.resetPass(params).then(function(response){
        let data = response.data.data;
                
        context.commit('loading', false, {root: true});
        resolve(data);
      })
      .catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  async addressUpdate(context, params) {
    context.commit('loading', true, {root: true});

    return new Promise(function(resolve, reject){
      User.addressUpdate(params).then(function(response){        
        let data = response.data;
                
        context.commit('loading', false, {root: true});
        resolve(data);
      })
      .catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  async deleteAddress(context, params) {
    context.commit('loading', true, {root: true});

    return new Promise(function(resolve, reject){
      User.deleteAddress(params).then(function(response){
        
        context.dispatch('getUser');      
        context.commit('loading', false, {root: true});
        resolve();
      })
      .catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  async recoverPass(context, params) {
    context.commit('loading', true, {root: true});

    return new Promise(function(resolve, reject){
      User.recoverPass(params).then(function(response){
        let data = response.data.data;
                
        context.commit('loading', false, {root: true});
        resolve(data);
      })
      .catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  async registerOrUpdate(context, params) {
    context.commit('loading', true, {root: true});  

  	return new Promise(function(resolve, reject){
      User.[METHOD_REGISTER[params.segment]](params).then(function(response){
        let data = response.data.data;
        
        context.commit('setUser', data);        
        context.commit('loading', false, {root: true});

        resolve(data);
      })
      .catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
};