<template>
  <div>
    <!-- <b-button v-b-modal.modalTerms>Show Modal</b-button> -->
    <b-modal id="modalFaqs" title="Faqs" ok-only>
      <div class="content-modal-terms">
        <div class="content-modal-terms-text">
          <p>
            <span class="mt-0"
              >1 - O que posso consultar na minha área pessoal?
            </span>
            A área pessoal é onde poderá aceder a todos os seus dados pessoais e
            fazer alterações que considerar adequadas. Poderá também consultar
            todas as suas encomendas, o código de rastreio e a situação do
            pedido das mesmas

            <span
              >2 – Quais as áreas geográficas abrangidas para envio de
              encomendas?</span
            >
            Entregamos encomendas nos seguintes concelhos: <br>
            <strong>- Lisboa</strong><br> 
            <strong>- Oeiras </strong><br>
            <strong>- Cascais</strong><br>
            <strong>- Odivelas</strong><br>
            <strong>- Loures </strong><br>
            <strong>- Amadora</strong><br>
            Esperamos em breve alargar a mais localizações.<br> 
            Se pretende receber a sua encomenda por Correio Registado por favor contacte-nos.

            <span
              >3 – Como sei que a minha morada e encontra nas áreas
              abrangidas?</span
            >
            Quando efetuar a sua encomenda coloque o Código Postal da morada
            onde quer receber a sua encomenda, de seguida o sistema dá-lhe esta
            informação. Esteja atento em breve teremos mais localizações
            disponíveis.

            <span>4- Quais são os métodos de pagamento disponíveis?</span>
            Temos atualmente 2 métodos de pagamento disponíveis: <br />
            <strong>• MB Way</strong> <br />
            <strong>• Transferência Bancária</strong>

            <span
              >5 - Como devo proceder para devolver um determinado artigo ou
              encomenda?</span
            >
            Para proceder à devolução de uma encomenda, envie-nos um email para:
            <strong>MYLKYNUTS@GMAIL.COM</strong> <br />
            Trataremos com o máximo de brevidade possível de qualquer assunto
            que surja com a sua encomenda.

            <span>6 - Posso cancelar o meu registo no Mylky Nuts?</span>
            Sem dúvida! na sua área pessoal pode exercer aos seguintes direitos
            no âmbito do RGPD:<br />
            <strong>• Direito ao Acesso aos teus dados pessoais</strong><br />
            <strong>• Direito à retificação dos teus dados pessoais</strong
            ><br />
            Para exercer o direito ao esquecimento e portabilidade, envie-nos um
            email para: <strong>MYLKYNUTS@GMAIL.COM</strong>
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#modalFaqs {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 700px;
    }
  }
  .modal-footer {
    .btn-primary {
      height: 45px;
      width: 70px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color5);
      border: 1px solid var(--main-color5);
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color);
      text-transform: uppercase;
      margin-left: 15px;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        background: var(--main-color);
        color: var(--main-color5);
      }
    }
  }
}
.content-modal-terms {
  &-text {
    p {
      font-size: 16px;
      font-family: "Font Regular";
      color: var(--main-color4);
      margin-bottom: 0;
      span {
        text-transform: uppercase;
        font-family: "Font Bold";
        display: block;
        margin-top: 30px;
      }
      a {
        text-decoration: underline;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
