import Products from '@/models/Products';

export default {
  async setProductSections(context, value) {  	
  	context.commit('loading', true);
	await Products.getProductSections(value).then(function(response){
		let data = response.data.data;
		
		context.commit('setProductSections', data);		
		context.commit('loading', false);
	})
	.catch(error => context.dispatch('setError', error, { root: true }));
  },
};