<template>
  <b-modal id="modalErrorPayment" v-model="errorPayment" title="Selecionar o método de pagamento" ok-only>
    <div class="content-modal-mensage">
      <h2>
        Por favor selecione o método de pagamento.
      </h2>
    </div>
  </b-modal>
</template>

<script>
  import { mapGetters, mapMutations} from 'vuex';
  
  export default {
    computed: {
      errorPayment: {
        get(){
          return this.$store.getters.errorPayment
        },
        set(value){
          value = false;
          this.$store.commit('setModalErrorPayment', value);
        } 
      }
    },
  }
</script>

<style lang="scss">
#modalErrorPayment {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 700px;
    }
  }
  .modal-footer {
    .btn-primary {
      height: 45px;
      width: 70px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color5);
      border: 1px solid var(--main-color5);
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color);
      text-transform: uppercase;
      margin-left: 15px;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        background: var(--main-color);
        color: var(--main-color5);
      }
    }
  }
}
.content-modal-mensage {
  h2 {
    font-size: 20px;
    font-family: "Font Regular";
    color: var(--main-color4);
    margin-bottom: 0;
		span {
			display: block;
		}
  }
}
</style>