<template>
  <div class="home">
    <div class="content-home">
      <banner-one />
      <!-- <links-one /> -->
      <div class="content-home-section">
        <div class="content-home-section-itens container">
          <div class="content-home-section-itens-header">
            <h2 class="title"> {{ $t('home.our_products') }}</h2>
          </div>
          <div v-if="Object.keys(configs).length > 0" class="content-home-section-itens-body">
            <!--  -->
              <template v-for="(section, index) in configs.product_sections">
                <product-sections :key="index" :options="section"></product-sections>
              </template>
              <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerOne from "@/components/banner/BannerOne.vue";
// import LinksOne from "@/components/links/LinksOne.vue";
import ProductSections from "@/components/ProductSections/views";
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    BannerOne,
    // LinksOne,
    ProductSections 
  },
  computed: {
    ...mapGetters('SiteConfigs', ['configs']),
  },
}
</script>
<style lang="scss">
.content-home {
  &-section {
    padding: 100px 0px;
    @media (max-width: 991px){
      padding: 50px 0px;
    }
    &-itens {
      &-header {
        margin-bottom: 90px;
        @media (max-width: 991px){
          margin-bottom: 30px;
        }
        h2 {
          font-size: 48px;
          font-family: 'Font Bold';
          color: var(--main-color5);
          margin-bottom: 0;
          text-transform: uppercase;
          @media (max-width: 991px){
            font-size: 32px;
          }
        }
      }
    }
  }
}
</style>