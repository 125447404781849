<template>
  <section class="content-recipes">
    <div class="content-recipes-itens container">
      <router-link tag="a" class="btn-return" :to="{ name: 'Home' }">
        <img
          class="img-fluid"
          src="@/assets/img/arrow-left.png"
          alt="arrow left"
          title="arrow left"
        />
        {{ $t('btns_default.return') }}
      </router-link>
      <div class="content-recipes-itens-header">
        <h1>{{ $t('home.recipes') }}</h1>
      </div>
      <div class="content-recipes-itens-body">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-4">
            <router-link
              tag="a"
              :to="{ name: 'RecipesIntern' }"
              class="content-link"
            >
              <div class="content-link-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/recipes/image-1.jpg"
                  alt="image"
                  title="image"
                />
              </div>
              <div class="content-link-text">
                <h2>Title recipe</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus
                </p>
              </div>
              <div class="content-link-date">
                <p>12 January, 2020</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <router-link
              tag="a"
              :to="{ name: 'RecipesIntern' }"
              class="content-link"
            >
              <div class="content-link-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/recipes/image-2.jpg"
                  alt="image"
                  title="image"
                />
              </div>
              <div class="content-link-text">
                <h2>Title recipe</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus
                </p>
              </div>
              <div class="content-link-date">
                <p>12 January, 2020</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <router-link
              tag="a"
              :to="{ name: 'RecipesIntern' }"
              class="content-link"
            >
              <div class="content-link-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/recipes/image-3.jpg"
                  alt="image"
                  title="image"
                />
              </div>
              <div class="content-link-text">
                <h2>Title recipe</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus
                </p>
              </div>
              <div class="content-link-date">
                <p>12 January, 2020</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <router-link
              tag="a"
              :to="{ name: 'RecipesIntern' }"
              class="content-link"
            >
              <div class="content-link-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/recipes/image-4.jpg"
                  alt="image"
                  title="image"
                />
              </div>
              <div class="content-link-text">
                <h2>Title recipe</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus
                </p>
              </div>
              <div class="content-link-date">
                <p>12 January, 2020</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <router-link
              tag="a"
              :to="{ name: 'RecipesIntern' }"
              class="content-link"
            >
              <div class="content-link-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/recipes/image-5.jpg"
                  alt="image"
                  title="image"
                />
              </div>
              <div class="content-link-text">
                <h2>Title recipe</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus
                </p>
              </div>
              <div class="content-link-date">
                <p>12 January, 2020</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <router-link
              tag="a"
              :to="{ name: 'RecipesIntern' }"
              class="content-link"
            >
              <div class="content-link-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/recipes/image-6.jpg"
                  alt="image"
                  title="image"
                />
              </div>
              <div class="content-link-text">
                <h2>Title recipe</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus
                </p>
              </div>
              <div class="content-link-date">
                <p>12 January, 2020</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="content-pagination">
              <button class="content-pagination-prev">
                <img
                  class="img-fluid"
                  src="@/assets/img/arrow-left.png"
                  alt="arrow left"
                  title="arrow left"
                />
                <p>Prev</p>
              </button>
              <div class="content-pagination-links">
                <a class="active"> 1 </a>
                <a> 2 </a>
                <a> 3 </a>
              </div>
              <button class="content-pagination-next">
                <p>Next</p>
                <img
                  class="img-fluid"
                  src="@/assets/img/arrow-right.png"
                  alt="arrow right"
                  title="arrow right"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
.btn-return {
  display: flex;
  align-items: center;
  background: 0;
  border: 0;
  font-size: 16px;
  font-family: "Font Bold";
  color: var(--main-color4) !important;
  padding: 0;
  text-decoration: none;
  transition: 500ms;
  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: none;
  }
  img {
    margin-right: 5px;
  }
}
.content-recipes {
  padding: 70px 0px;
  @media (max-width: 991px) {
    padding: 30px 0px;
  }
  &-itens {
    &-header {
      margin-bottom: 90px;
      @media (max-width: 991px) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      h1 {
        font-size: 48px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 991px) {
          font-size: 32px;
        }
      }
    }
    &-body {
      .content-link {
        display: block;
        text-decoration: none;
        transition: 500ms;
        margin-bottom: 90px;
        @media (max-width: 576px) {
          margin-bottom: 50px;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
          .content-link-image {
            img {
              transform: scale(1.1);
            }
          }
        }
        &-image {
          overflow: hidden;
          img {
            transition: 500ms;
          }
        }
        &-text {
          text-align: left;
          margin-top: 20px;
          h2 {
            font-size: 25px;
            font-family: "Font Bold";
            color: var(--main-color4);
            margin-bottom: 20px;
            @media (max-width: 576px) {
              font-size: 20px;
            }
          }
          p {
            font-size: 20px;
            font-family: "Font Light";
            color: #a7a9a7;
            @media (max-width: 576px) {
              font-size: 16px;
            }
          }
        }
        &-date {
          text-align: left;
          margin-top: 20px;
          p {
            font-size: 16px;
            font-family: "Font Bold";
            color: #a7a9a7;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  &-prev,
  &-next {
    display: flex;
    align-items: center;
    background: 0;
    border: 0;
    transition: 500ms;
    p {
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color4);
      margin-bottom: 0;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  &-prev {
    margin-right: 30px;
  }
  &-next {
    margin-left: 30px;
  }
  &-links {
    display: flex;
    align-items: center;
    a {
      height: 44px;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: 0;
      border: 1px solid #ebebeb;
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color4) !important;
      margin-right: 5px;
      transition: 500ms;
      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.6;
      }
    }
    .active {
      background: var(--main-color4);
      border: 1px solid var(--main-color4);
      color: var(--main-color) !important;
    }
  }
}
</style>