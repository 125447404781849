import Cart from '@/models/Cart';

export default { 
  openCartFloating(context, value){
    context.commit('openCartFloating', value);    
    context.dispatch('setOffcanvasOpen', value, { root: true });
  },
  setCartPayload(context, payload) {
    context.commit('setCartPayload', payload);    
  },
  async clearCartPayload(context) {
    context.commit('clearCartPayload');    
  },
  addProductToCart(context, value) {
    let payload;
    let product;

    if (value){
      payload = {
        product: value,
        product_id: value.id,
      }
      product = value;
    } else {
      payload = context.getters.cartPayload;  
      product = payload.product;
    }

    // console.log('Payload:', payload);
    // console.log('Product:', product);
    
    let alert = null;

    if(product.required_variant_color && !payload.variant_color_id){
      alert = 'Selecione qual a cor do produto que você deseja.'
    }

    if(product.required_variant_size  && !payload.variant_size_id){
      alert = 'Selecione qual o tamanho do produto que você deseja.'
    }

    if(alert){
      context.dispatch('setAlert', {
        type: 'info', 
        title: 'Atenção', 
        message: alert,
      },{root:true});  

      return;
    }
    
  	context.dispatch('loading', true, { root: true });

    
    return new Promise(function(resolve, reject){
        Cart.addProductToCart(payload).then(function(response){
          let data = response.data.data;
          context.commit('setProductsCart', data);

          context.dispatch('loading', false, { root: true }); 

          context.dispatch('setNotify', {
            text: 'adicionado ao seu cesto',
            type: 'sucess',
          }, {root: true});
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  getCartProducts(context) {
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Cart.getCartProducts().then(function(response){
          let data = response.data.data;
          
          context.commit('setProductsCart', data);        
          context.commit('loading', false);
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  updateCartItem(context, payload) {
    context.dispatch('loading', true, { root: true });

    return new Promise(function(resolve, reject){
        Cart.updateCartItem(payload).then(function(response){
          let data = response.data.data;
          
          context.commit('setProductsCart', data);
          context.dispatch('loading', false, { root: true });
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  updateCartCheckout(context, payload) {
    context.dispatch('loading', true, { root: true });
    return new Promise(function(resolve, reject){
        Cart.updateCartCheckout(payload).then(function(response){
          let data = response.data.data;
          
          context.commit('setProductsCart', data);
          context.dispatch('loading', false, { root: true });

          resolve(data);
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  checkout(context, payload) {
    context.dispatch('loading', true, { root: true });
    return new Promise(function(resolve, reject){
        Cart.checkout(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          context.commit('setProductsCart', {});
          context.dispatch('loading', false, { root: true });

          resolve(data);
        }).catch(error =>{
           context.dispatch('setError', error, { root: true });
           reject()
        });
    });
  },
  removeItemCart(context, [id, value]) {
    context.dispatch('loading', true, { root: true });

    return new Promise(function(resolve, reject){
        Cart.removeItem({ item_id: id }).then(function(response){
          let data = response.data.data;
          
          context.commit('setProductsCart', data);
          context.dispatch('loading', false, { root: true });
          context.dispatch('updateCartCheckout', value);
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
};