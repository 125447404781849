<template>
  <section class="content-banner">
    <div class="content-banner-itens">
      <swiper :options="swiperOptions">
        <swiper-slide v-for="(slide, index) in banners" :key="index">
          <div class="content-banner-itens-image" v-lazy:background-image="slide.desktop">
          </div>
          <div class="content-banner-itens-text">
            <h1>{{slide.html_title}}<span>{{slide.html_subtitle}}</span></h1>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- <div class="swiper-button-prev d-none d-lg-flex" slot="button-prev">
          <svg
            fill="#fff"
            width="30"
            height="30"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-left"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path
              d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
              class=""
            ></path>
          </svg>
        </div>
        <div class="swiper-button-next d-none d-lg-flex" slot="button-next">
          <svg
            fill="#fff"
            width="30"
            height="30"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path
              d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
              class=""
            ></path>
          </svg>
        </div> -->
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapGetters('Banner', ['banners', 'loading']),      
  },
  methods: {      
    ...mapActions('Banner', ['listBanners'])
  },
  mounted(){
    this.listBanners();
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 800,
        watchOverflow: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
			},
			bgImage: {
        backgroundImage: `url(${require('@/assets/img/banner/banner.jpg')})`
      }
    };
  },
};
</script>

<style lang="scss">
.content-banner {
  &-itens {
		&-image {
			height: 610px;
			width: 100%;
			background-position: center !important;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			@media (min-width: 992px) and (max-height: 800px) {
				height: 500px;
			}
			@media (max-width: 991px){
				height: 400px;
			}
    }
    &-text {
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 767px){
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      h1 {
        font-size: 100px;
        color: var(--main-color);
        margin-bottom: 0;
        font-family: 'Font Bold';
        line-height: 1;
        @media (max-width: 767px){
          font-size: 32px;
        }
        span {
          display: block;
        }
      }
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  &::after {
    content: none;
  }
}
.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  background: 0;
  width: 60px;
  height: 60px;
  background: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.swiper-pagination {
  &-bullet {
    width: 12px;
    height: 4px;
    background: var(--main-color);
    opacity: 1;
    cursor: pointer;
		border-radius: 0;
    margin: 0px 7.5px !important;
    outline: none !important;
    @media (max-width: 991px){
      background: #e0e0e0;
    }
  }
  &-bullet-active {
		background: var(--main-color4);
		width: 30px;
  }
}
</style>
