<template>
  <aside class="aside-menu d-lg-none" :class="{ 'aside-menu-active': menuOpen }">
    <div class="aside-menu-itens">
      <div class="aside-menu-itens-header">
        <router-link tag="a" :to="{name: 'Home'}" class="d-block">
          <img
            v-if="configs.logo_path"
            class="img-fluid"
            v-lazy="configs.logo_path"
            alt="logo company"
            title="logo company"
          />
          <img
            v-else
            class="img-fluid"
            src="@/assets/img/logo-company.png"
            alt="logo company"
            title="logo company"
          />
        </router-link>
        <div class="content-menu">
          <a class="content-menu-item btn-menu" @click="setMenuOpen(false)">
            <span class="active"></span>
            <p>{{ $t('menu.close') }}</p>
          </a>
        </div>
      </div>
      <div class="aside-menu-itens-links">
        <router-link @click.native="setMenuOpen(false)" tag="a" :to="{name: 'Home'}" class="links"> 
          {{ $t('header.home') }}
        </router-link>
        <template v-if="Object.keys(user).length == 0">
          <router-link @click.native="setMenuOpen(false)" tag="a" :to="{name: 'Register'}" class="links">
            {{ $t('header.register') }}
          </router-link>
          <router-link @click.native="setMenuOpen(false)" tag="a" :to="{name: 'Login'}" class="links">
            {{ $t('header.login') }}
          </router-link>
          <router-link @click.native="setMenuOpen(false)" class="links" tag="a" :to="{name: 'cart' }"> 
            {{ $t('header.cart') }}
          </router-link>
        </template>
        <template v-else>
          <router-link @click.native="setMenuOpen(false)" tag="a" :to="{name: 'MyAccount' }" class="links">
            {{ $t('header.my_account') }}
          </router-link>
          <router-link @click.native="setMenuOpen(false)" tag="a" :to="{name: 'Wishlist' }" class="links">
            {{ $t('header.wishlist') }}
          </router-link>
          <router-link @click.native="setMenuOpen(false)" tag="a" :to="{name: 'cart' }" class="links">
            {{ $t('header.cart') }}
          </router-link>
          <a class="links" @click="logoff()">
            {{ $t('header.logoff') }}
          </a>
        </template>
      </div>
      <div class="aside-menu-itens-collapse">
        <button class="aside-menu-itens-collapse-btn" v-b-toggle.collapse-1>
          PT
          <div>
            <svg
              width="30"
              height="30"
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="angle-down"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
            >
              <path
                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                class=""
              ></path>
            </svg>
          </div>
        </button>
        <b-collapse id="collapse-1"> 
          <div class="card-body">
            <a class="card-body-links" href="#"> Português (PT-BR) </a>
            <a class="card-body-links" href="#"> Português (PT-PT) </a>
          </div>
        </b-collapse>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["menuOpen"]),
    ...mapGetters("User", ["user"]),
    ...mapGetters('SiteConfigs', ['configs']),
  },
  methods: {
    ...mapMutations(["setMenuOpen"]),
    ...mapActions("User", {
      userLogoff: "logoff",
    }),
    logoff() {
      this.userLogoff();
      this.setMenuOpen(false);
      if(this.$route.name != 'Home'){
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style lang="scss">
.aside-menu {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background: var(--main-color5);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 30px;
  transition: 0.8s ease-in-out;
  left: -100%;
  pointer-events: none;
  z-index: 1030;
  &-active {
    left: 0;
    pointer-events: initial;
  }
  &-itens {
    height: 100%;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: 1px solid rgba(255,255,255,0.5);
      // img {
      //   max-width: 60px;
      // }
    }
    &-links {
      padding-top: 20px;
      a {
        display: block;
        font-family: "Font Bold";
        color: var(--main-color) !important;
        font-size: 20px;
        margin-bottom: 0;
        width: 100%;
        padding: 15px;
        transition: 500ms;
        text-align: left;
        text-decoration: none !important;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.5;
        }
      }
      .links {
        display: flex;
        align-items: center;
        p {
          font-family: "Font Bold";
          color: var(--main-color) !important;
          font-size: 20px;
          margin-bottom: 0;
          width: 80px;
        }
        &-icon {
          height: 56px;
          width: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--main-color);
          border-radius: 100%;
          margin-left: 10px;
          svg {
            fill: var(--main-color);
            max-width: 30px;
          }
        }
      }
    }
    .card {
      border: 0;
      background: 0;
      border-radius: 0px;
      &-header {
        border: 0;
        background: 0;
        border-radius: 0px;
        padding: 0;
      }
      .btn-link {
        padding: 15px;
        padding-bottom: 0px;
        font-size: 20px;
        color: var(--main-color5);
        font-family: "Font Bold";
        border: 0;
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: none;
        & > div {
          transition: 500ms;
          margin-left: 10px;
          svg {
            fill: var(--main-color5);
            transition: 500ms;
          }
        }
      }
      [aria-expanded="true"] {
        opacity: 0.8;
        & > div {
          transform: rotate(180deg);
        }
      }
      &-body {
        padding: 0;
        &-links {
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          padding-left: 30px;
          color: var(--main-color5);
          font-size: 14px !important;
          letter-spacing: 1.5px;
          font-family: "Font Light";
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
          }
        }
      }
    }
    &-collapse {
      &-btn {
        font-family: "Font Bold";
        color: var(--main-color5) !important;
        background: 0;
        border: 0;
        font-size: 20px;
        display: block;
        width: 100%;
        padding: 15px;
        transition: 500ms;
        text-align: left;
        display: flex;
        align-items: center;
        & > div {
          transition: 500ms;
          margin-left: 10px;
          svg {
            fill: var(--main-color5);
            transition: 500ms;
          }
        }
        &[aria-expanded="true"] {
          opacity: 0.8;
          & > div {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>