import axios from 'axios';

export default {
	async getProductSections(params){
		return axios.post('/api/v1/products/get-product-sections', params);
	},
	async getProductDetails(params){
		return axios.get('/api/v1/products/get-product-details/'+params);
	},
	async addRatingProduct(params){
		return axios.post('/api/v1/products/add-rating-product/',params);
	},
	async getProductRelated(params){
		return axios.get('/api/v1/products/get-product-related/'+params);
	},
	async getSearchProducts(params){		
		return axios.get('/api/v1/products/search-lists', {params:params});
	}
}