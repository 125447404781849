export default {  
  setCartPayload(state, payload) {
    state.payload = {...state.payload, ...payload};
  },
  clearCartPayload(state) {
    state.payload = {};
  },
  setWishlist(state, value) {
  	state.products = value;
  },
  openWishlist(state, value){
    state.wishlistOpen = value;
  }
};