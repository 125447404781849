export default [
  {
    path: '/produtos',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */'@/views/Products/views'),
    children: [      
      {
        path: ':slug',
        name: 'product-page',
        component: () => import(/* webpackChunkName: "products" */'@/views/Products/views'),
      },
    ],
  },
];