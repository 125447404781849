<template>
  <div>
    <!-- <b-button v-b-modal.modalTerms>Show Modal</b-button> -->
    <b-modal id="modalTerms" :title="$t('modals.terms_and_conditions')" ok-only>
      <div class="content-modal-terms">
        <div class="content-modal-terms-text">
          <p>
            <span class="mt-0">AVISO LEGAL</span>
            <strong>1.</strong> O presente documento visa fixar os termos e condições
            da prestação de serviços / venda pela Inward, Lda. no seu website
            www.mylkynuts.com (“Website”), por via da qual disponibiliza os seus
            produtos aos seus clientes. <br>
            <strong>2.</strong> Mediante a colocação de uma encomenda
            de um produto através do Website, o Uitlizador aceita que os
            presentes Termos e Condições são aplicáveis à venda e utilização do
            Website, manifestando a respetiva aceitação mediante aposição de um
            visto no final da presente página; caso não o faça, não poderá
            proceder à encomenda dos nossos produtos. <br>
            <strong>3.</strong> A Inward reserva-se o
            direito de alterar os presentes Termos e Condições, fazendo nota das
            datas das respetivas alterações no topo deste documento, pelo que
            recomenda a respetiva consulta quando efetue uma encomenda. Os
            termos e condições aplicáveis serão aqueles que estiverem em vigor
            na data em que efetue um pedido/encomenda. Recomendamos que faça uma
            cópia dos mesmos para sua consulta e registo. <br>
            <strong>4.</strong> Os seus dados
            pessoais que sejam solicitados no Website, são necessários e
            tratados pela Inward para a prestação dos serviços, podendo ser
            comunicados aos seus fornecedores para as mesmas finalidades. A
            Inward procede igualmente ao processamento do seu nome e mail para
            fins de marketing, podendo o titular dos dados opor-se a este
            tratamento. Poderá solicitar a suspensão, cancelamento e correção
            dos dados para o email mylkynuts@gmail.com.

            <span>1. INWARD, LDA. </span> 
            A Inward, Lda. é uma sociedade comercial por quotas, com número de
            identificação fiscal 516 288 733, registada na Conservatória do
            Registo Comercial de Lisboa sob o mesmo número, e com capital social
            no montante de 500,00€ (quinhentos euros) integralmente realizado e
            com sede social na Rua Rua de Pedrouços 67B, 1400-285 Lisboa. A
            Inward vende manteigas de frutos secos (“Produtos”), de acordo com a
            composição indicada na página de cada Produto, e procede à respetiva
            entrega na morada indicada pelo utilizador quando da respetiva
            solicitação durante o processo de pagamento. As referidas entregas
            estão limitadas à zona de Lisboa, e mediante pagamento de uma taxa
            de entrega às zonas Cascais e Sintra, nas condições indicadas no
            processo de compra. Nos termos da lei 24/2014, uma vez que se tratam
            de géneros alimentícios, o utilizador não tem direito ao exercício
            da resolução do contrato. 

            <span> 2. ACESSO E CONDIÇÕES DO WEBSITE </span>
            O website
            é público, pelo que pode ser acedido sem que seja necessário
            registar-se, no entanto será necessário registar-se para efetuar uma
            encomenda de Produto. Desta forma, o utilizador deve manter
            confidenciais os seus dados de registo, e o perfil registado. 

            <span>3.PROCESSAMENTO DO PEDIDO/ENCOMENDA</span>
            Ao registar-se no Website o utilizador declara ter capacidade jurídica necessária para celebrar
            os negócios jurídicos disponíveis no website. O utilizador
            compreende que pode escolher os Produtos a partir do website,
            pressionando no botão “comprar”, e “encomendar” ou outro botão com
            significado equivalente. Posteriormente, deve rever as informações,
            indicar se tem alguma alergia alimentar, e verificar eventuais erros
            antes de pressionar no botão “avançar”, após o que não poderá
            alterar ou corrigir a encomenda. Nestes termos, o Utilizador
            compreende que depois de finalizar estes passos e carregar no botão
            “avançar” e, após confirmação do respetivo pagamento, não pode
            proceder ao cancelamento ou reembolso dos montantes pagos. Desta
            forma, quando o pagamento não seja autorizado ou efetuado pela
            instituição bancária do cliente, a sua encomenda não será aceite e
            processada. Após receção da encomenda e do respetivo pagamento, a
            Inward inicia o processamento do pedido e envia um email (para o
            endereço de email facultado quando da encomenda) ao Utilizador
            notificando-o que o seu pedido foi recebido e será processado. O
            utilizador aceita que o tempo previsto para a entrega dos “Produtos”
            é meramente indicativo, não assumindo a Inward qualquer obrigação de
            cumprimento dos mesmos. O utilizador ainda reconhece e aceita a
            entrega será feita na morada facultada, mas que se não abrir a
            porta/ houver resposta através do contacto telefónico fornecido
            (sempre que este seja fornecido) após o período de 10 (dez) minutos
            a contar a chegada do estafeta a essa morada, a Inward reserva-se ao
            direito de pôr fim ao serviço e manter a facturação do mesmo ao
            Utilizador, que não tem direito de exigir qualquer reembolso do
            preço pago. 

            <span>4. PREÇO, PAGAMENTO DOS SERVIÇOS E ENTREGA</span> 
            Os preços dos serviços são os indicados no Website, os quais incluem o IVA à taxa
            legal em vigor. De igual forma incluem uma taxa de entrega, com IVA
            incluído. As encomendas serão pagas pelo Utilizador através MB Net,
            emitindo a Inward a respetiva fatura/recibo eletrónico, enviada ao
            Utilizador, para o email registado no próprio website. As encomendas
            podem igualmente ser feitas por telefone, procedendo a Inward à
            execução da encomenda, a qual só será confirmada para após obtenção
            da confirmação de pagamento, enviando a fatura para o endereço de
            email que seja fornecido por Utilizador ou pessoa que procedeu ao
            pagamento. Caso a Inward não receba a informação completa do
            Utilizador, tal como o nome de quem vai receber a entrega, a morada
            ou telefone de contacto, a entrega não é garantida. A Inward
            empenhará os seus melhores esforços para proceder à entrega na
            morada indicada, uma vez recebido o pagamento. Caso pretenda fazer
            mais do que uma encomenda com entrega em mais do que um local, deve
            enviar-nos um email logo que tenha executado a encomenda,
            indicando-nos os dados de entrega (nome, morada e contacto). 

            <span>5.SERVIÇO DE APOIO/SUPORTE AO UTILIZADOR</span>
            Caso se depare com problemas na sua encomenda, poderá contactar-nos telefonicamente para o número
            disponibilizado no Website. Caso a Inward se se depare com um
            problema com a sua encomenda, poderá entrar em contacto, para o
            número de telefone fornecido pelo utilizador. De igual forma, a
            Inward reserva-se o direito de entrar em contacto consigo para o
            telefone fornecido, para saber se ficou satisfeito com a qualidade
            do serviço. 

            <span> 6. DIREITOS DE PROPRIEDADE INTELECTUAL </span>
            O Utilizador poderá utilizar o website, fazer impressões ou download das
            funcionalidade e páginas apenas para uso pessoal, desde que não
            utilize o Website para qualquer fim ilegal e/ou ilícito. O
            utilizador reconhece que a Inward é a exclusiva titular de todos os
            direitos de propriedade intelectual e industrial relativos ao
            Website, respetivos conteúdos, salvo quantos aos comentários
            publicados pelos Utilizadores. O utilizador reconhece que o Website
            não poderá ser reproduzido/ armazenado em nenhum outro website, nem
            ser incluído em qualquer sistema ou serviço de recuperação
            eletrónica de dados. 

            <span>7. ACESSO AOS SERVIÇOS O utilizador</span> 
            reconhece que a Inward empenhará os melhores esforços para que o website
            esteja disponível 24h (vinte e quatro horas) por dia, todos os dias,
            mas não se responsabiliza pela respetiva indisponibilidade por
            qualquer período de tempo, salvo se resultar de facto que lhe seja
            exclusivamente imputável a título de dolo ou de culpa grave. O
            Utilizador reconhece que o acesso ao Website poderá ser suspenso
            temporariamente, sem qualquer aviso. O utilizador reconhece o risco
            de transmissão de dados na Internet pelo que não garante a segurança
            dos dados transmitidos pelo Utilizador no Website.

            <span> 8. DADOS E CONTEÚDOS DO UTILIZADOR </span> 
            Os dados pessoais fornecidos pelo Utilizador
            estão sujeitos à “Política de Privacidade”, sendo os restantes dados
            e informações introduzidas pelo utilizador serão considerados como
            dados não confidenciais. O utilizador declara ainda expressamente
            que os dados e informações por si introduzidos no website: não
            violam a Lei aplicável; não são ilegais ou fraudulentos; não
            implicam publicidade não autorizada ou; não contem vírus ou malware;
            não contêm: conteúdo difamatório, obsceno ou ofensivo; não promovem
            a violência ou a discriminação; não violam os direitos ou obrigações
            legítimas de terceiros; Não promovem atividades ilegais; não podem
            ser usadas para falsear ou ocultar a identidade do utilizador e/ou a
            sua relação com terceiros. A Inward reserva-se ao direito de
            eliminar e editar (se assim o entender, ou quando tal seja imposto
            por lei) os comentários e publicações que violem o ora disposto. O
            Utilizador reconhece ainda que é o exclusivo responsável pelos seus
            comentários publicados no Website, não assumindo responsabilidade
            quanto ao teor dos mesmos. 

            <span> 9. ISENÇÃO DE RESPONSABILIDADE </span>
            O utilizador aceita que apesar dos seus empenhar os seus melhores
            esforços para que os conteúdos do Website estejam correctos, não
            pode garantir a respetiva atualização a todo o momento. A Inward
            pode, a qualquer momento e sem aviso prévio, alterar os conteúdos e
            serviços. A Inward disponibiliza e descreve os seus produtos
            incluindo alergénios alimentares. Através da utilização do Website,
            o utilizador reconhece que: (i) As fotografias dos produtos são
            meramente indicativas podendo os produtos ter uma aparência
            diferente de acordo com a respetiva forma de composição e frutas
            disponíveis; (ii) Os prazos de entrega dos Produtos são meramente
            indicativos, sendo meras previsões. A Inward não garante, nem assume
            qualquer responsabilidade relacionada com o cumprimento dos prazos
            de entrega /disponibilização dos Produtos. (iii) A Inward pretende
            aceitar todas as encomendas na zona de Lisboa, mas reserva-se o
            direito de cancelar as encomendas logo que constate a falta de
            stock, dando de imediato contada recusa da encomenda, e procedendo à
            emissão da ordem de reembolso do valor pago. 

            <span> 10. RESPONSABILIDADE </span>
            O utilizador aceita que a Inward não será responsável, salvo em caso
            de dolo ou culpa grave, por quaisquer danos emergentes ou
            directamente relacionados com a prestação dos serviços em causa
            através do seu website, incluindo a utilização, a impossibilidade de
            utilização ou o resultado da utilização do website. Nomeadamente não
            será responsável por: (i) lucros ou receitas; (ii) perda ou
            corrupção de dados, informações ou software; (iii) pela perda de
            oportunidades de negócios; (iii) por danos de imagem. O utilizador
            aceita que, salvo em caso de dolo ou culpa grave, a responsabilidade
            resultante da utilização e dos serviços do website, está limitada a
            um montante correspondente a duas vezes o valor do encomenda pago
            pelo utilizador. 
            
            <span> 11. RESOLUÇÃO CONTRATUAL </span> 
            A Inward poderá cancelar
            ou suspender o direito de utilização do website e os serviços
            mediante notificação do utilizador, através de email, em caso de
            incumprimento das obrigações ora determinadas nomeadamente em (i)
            caso de violação relativa a direitos de propriedade intelectual;
            (ii) caso de violação dos “Termos e Condições do website”
            respeitantes a conteúdos. 
            
            <span> 12. COMUNICAÇÕES ESCRITAS </span> 
            O utilizador aceita que as comunicações escritas, necessárias ao abrigo dos
            presentes “Termos e Condições do website”, serão realizadas pela
            Inward para o endereço de e-mail disponibilizado pelo utilizador com
            vista à realização de encomendas ou por sms para o telefone
            fornecidos pelo Utilizador. 
            
            <span> 13. CAUSAS DE FORÇA MAIOR </span> 
            Inward não será responsável por qualquer falha ou atraso no cumprimento das
            suas obrigações quando estas estejam foram do seu controlo (“ causas
            de força maior”), nomeadamente: (i) Greves; (ii) agitação civil,
            ataques terroristas; (iii) incêndios, inundações, terremotos; (iv)
            Falhas de energia e/ou falhas nas redes de telecomunicações; (v)
            Cumprimento de normas legais ou regulamentares ou administrativas.
            
            <span> 14. POLÍTICA DE PRIVACIDADE </span> 
            O utilizador aceita que os seus dados pessoais recolhidos para cumprimento dos presentes “Termos e
            Condições do website” serão tratados de acordo com a “Política de
            Privacidade” (em anexo) e, em conformidade com o disposto na
            legislação vigente (“Lei da Protecção de Dados Pessoais”) . 
            
            <span>15. INVALIDADE E REDUÇÃO DOS “TERMOS E CONDIÇÕES DO WEBSITE” </span>
            Se uma cláusula dos presentes “Termos e Condições do website” for
            considerada ilegal ou inexequível, esta desconsideração não afeta a
            validade das restantes, procedendo-se à redução nos termos da lei.
            
            <span>16. LEI APLICÁVEL E FORO COMPETENTE </span> 
            Para a resolução de quaisquer litígios relacionados com a interpretação ou execução dos presentes
            “Termos e Condições do Website”, é exclusivamente aplicável a lei
            Portuguesa e competente o foro da Comarca de Lisboa, com renúncia
            expressa a qualquer outro, salvo previsão legal em contrário. 
            
            <span> 17. RESOLUÇÃO ALTERNATIVA DE LITÍGIOS DE CONSUMO (RAL) </span> 
            Atendendo ao disposto no artigo 18º da Lei n.º 144/2015, 8 de Setembro, mais se
            informa os Utilizadores que sejam consumidores, que a Lista completa
            de Entidades (RAL) – Entidades de Resolução Alternativa de Litígios
            de Consumo em Portugal pode ser consultada em
            <a target="_blank" rel="noopener" href="http://www.consumidor.pt/">http://www.consumidor.pt/. </a> E bem assim informa sobre a plataforma de
            resolução de litígios em linha (online) que pode ser utilizada
            quando as partes se encontrem domiciliadas num Estado da U.E. –
            Plataforma de RLL e que é acessível através do endereço electrónico
            <a target="_blank" rel="noopener" href="http://ec.europa.eu/consumers/odr/"> http://ec.europa.eu/consumers/odr/. </a> A Inward informa ainda que o seu
            email de contaco é mylkynuts@gmail.com – para os feitos Resolução
            Alternativa de Litígios.
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#modalTerms {
  @media (min-width: 768px){
    .modal-dialog {
      max-width: 700px;
    }
  }
  .modal-footer {
    .btn-primary {
      height: 45px;
      width: 70px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color5);
      border: 1px solid var(--main-color5);
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color);
      text-transform: uppercase;
      margin-left: 15px;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        background: var(--main-color);
        color: var(--main-color5);
      }
    }
  }
}
.content-modal-terms {
  &-text {
    p {
      font-size: 16px;
      font-family: 'Font Regular';
      color: var(--main-color4);
      margin-bottom: 0;
      span {
        text-transform: uppercase;
        font-family: 'Font Bold';
        display: block;
        margin-top: 30px;
      }
      a {
        text-decoration: underline;
        text-decoration-color: var(--main-color4) !important;
        color: var(--main-color4) !important;
        transition: 500ms;
        &:hover,&:focus {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }
}

</style>