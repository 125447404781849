<template>
	<swiper :options="swiperProducts">
		<template v-if="loading">
			<swiper-slide v-for="n in 4" :key="n">
				<PuSkeleton 
                    :count="4"
                    style="height: 30px;"></PuSkeleton>
			</swiper-slide>
		</template>
		<template v-else>
			<template v-for="(product, productIndex) in products">
                <swiper-slide :key="productIndex">
                        <card-product                                 
                            :product="product"
                            :index="productIndex"></card-product>                            
                </swiper-slide>
            </template>
		</template>
		<div class="swiper-pagination swiper-pagination-products" slot="pagination"></div>
		<!-- <div class="swiper-button-prev d-none d-lg-flex" slot="button-prev">
		<svg
			fill="#fff"
			width="30"
			height="30"
			aria-hidden="true"
			focusable="false"
			data-prefix="far"
			data-icon="chevron-left"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 256 512"
		>
			<path
			d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
			class=""
			></path>
		</svg>
		</div>
		<div class="swiper-button-next d-none d-lg-flex" slot="button-next">
		<svg
			fill="#fff"
			width="30"
			height="30"
			aria-hidden="true"
			focusable="false"
			data-prefix="far"
			data-icon="chevron-right"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 256 512"
		>
			<path
			d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
			class=""
			></path>
		</svg>
		</div> -->
	</swiper>	
</template>

<script>
	import CardProduct from './CardProduct';
	import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
	import {mapGetters} from 'vuex';
	import "swiper/css/swiper.css";

	export default {
		components: {
			Swiper,
			SwiperSlide,
			CardProduct,
		},
		directives: {
			swiper: directive,
		},
		computed: {
			...mapGetters('ProductSection', ['loading', 'products']),
		},
		data() {
		return {
			swiperProducts: {
			    slidesPerView: 1.6,
			    spaceBetween: 20,
			    speed: 800,
			    watchOverflow: true,
			    clickable: true,
			    autoplay: {
			      delay: 5000,
						},
						breakpoints: {
							768: {
								slidesPerView: 2.6,
							},
							992: {
								slidesPerView: 4,
							}
						},
			    pagination: {
			      el: ".swiper-pagination",
			      type: "bullets",
			      clickable: true,
			    },
			    navigation: {
			      nextEl: ".swiper-button-next",
			      prevEl: ".swiper-button-prev",
						},
					},
			};
		},
	};
</script>

<style lang="scss">
.link-product {
	text-decoration: none !important;
}
.content-products-one {
	margin-bottom: 30px;
	&:hover,&:focus {
		.content-products-one-cards-image {
			transform: scale(1.03);
			opacity: 0.8;
		}
	}
	&-cards {
		text-align: left;
		&-image {
			transition: 500ms;
			overflow: hidden;
		}
		&-text {
			padding-top: 20px;
			text-align: left;
			a {
				text-decoration: none !important;
			}
			.name {
				font-size: 25px;
				font-family: 'Font Bold';
				color: var(--main-color5);
				margin-bottom: 25px;
				@media (max-width: 1199px){
					height: 90px;
				}
			}
			.subtitle {
				font-size: 20px;
				font-family: 'Font Bold';
				color: var(--main-color5);
				margin-bottom: 25px;
			}
			.quantity {
				display: flex;
				align-items: center;
				a {
					padding: 5px 20px;
					font-size: 13px;
					font-family: 'Font Light';
					color: #707070 !important;
					border: 1px solid #E4E4E4;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: 500ms;
					margin-right: 12px;
					&:last-child {
						margin-right: 0;
					}
					&:hover,&:focus {
						cursor: pointer;
						border: 1px solid #000;
					}
				}
				.active {
					border: 1px solid #000;
				}
			}
			.price {
				margin-top: 13px;
				display: flex;
				align-items: center;
				&-icon {
					height: 45px;
					width: 45px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: var(--main-color5);
				}
				&-value {
					margin-left: 15px;
					font-size: 30px;
					font-family: 'Font Bold';
					color: var(--main-color4);
					margin-bottom: 0;
				}
			}
		}
	}
}
.swiper-pagination-products {
	bottom: 0 !important;
}
</style>