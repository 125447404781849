// import {protectedPage} from '@/utils';

export default [
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Register/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */'@/views/User/views/Login/Login.vue')
  },
  {
    path: '/recupery-password',
    name: 'RecuperyPassword',
    component: () => import(/* webpackChunkName: "RecuperyPassword" */'@/views/User/views/Password/Index.vue')
  },
  {
    path: '/atualizar-senha',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "RecuperyPassword" */'@/views/User/views/Password/ResetPassword.vue')
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import(/* webpackChunkName: "MyAccount" */'@/views/User/views/MyAccount/Index.vue')
  },
];