import {protectedPage} from '@/utils';

export default [
  {
    path: '/my-orders',
    name: 'MyOrders',
    component: () => import(/* webpackChunkName: "MyOrders" */'@/views/Orders/views/Index.vue')
  },
  {
    path: '/my-orders/:id',
    name: 'MyOrdersDetails',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "MyOrdersDetails" */'@/views/Orders/views/Details/Index.vue')
  },
];