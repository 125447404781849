<template>
  <div>
    <!-- <b-button v-b-modal.modalTerms>Show Modal</b-button> -->
    <b-modal id="modalContacts" :title="$t('modals.contacts')" ok-only>
      <div class="content-modal-terms">
        <div class="content-modal-terms-text">
          <p>
            {{ $t('modals.text_contacts') }}
          </p>
          <!-- <p><span> {{ $t('modals.household') }}: Rua de Pedrouços 67B, 1400-285 </span></p> -->
          <p>
            <span> email: <a>mylkynuts@gmail.com</a> </span>
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#modalContacts {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 700px;
    }
  }
  .modal-footer {
    .btn-primary {
      height: 45px;
      width: 70px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color5);
      border: 1px solid var(--main-color5);
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color);
      text-transform: uppercase;
      margin-left: 15px;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        background: var(--main-color);
        color: var(--main-color5);
      }
    }
  }
}
.content-modal-terms {
  &-text {
    p {
      font-size: 16px;
      font-family: "Font Regular";
      color: var(--main-color4);
      margin-bottom: 0;
      span {
        text-transform: uppercase;
        font-family: "Font Bold";
        display: block;
        a {
          text-decoration: underline;
          transition: 500ms;
          color: var(--main-color4) !important;
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
      a {
        text-decoration: underline;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>