<template>
  <div>
    <!-- <b-button v-b-modal.modalTerms>Show Modal</b-button> -->
    <b-modal id="modalPolity" :title="$t('modals.privacy_policy')" ok-only>
      <div class="content-modal-terms">
        <div class="content-modal-terms-text">
          <p>
            <span class="mt-0"> 1. INWARD, LDA. </span>
            A Inward, Lda. é uma sociedade comercial por quotas, com número de
            identificação fiscal 516 288 733, registada na Conservatória do
            Registo Comercial de Lisboa sob o mesmo número, e com capital social
            no montante de 500,00€ (quinhentos euros) integralmente realizado e
            com sede social na Rua Rua de Pedrouços 67B, 1400-285 Lisboa
            (adiante designada como Mylky Nuts). A Mylky Nuts vende manteigas de
            frutos secos (“Produtos”), de acordo com a composição indicada na
            página de cada Produto, e procede à respetiva entrega na morada
            indicada pelo utilizador quando da respetiva solicitação durante o
            processo de pagamento. As referidas entregas estão limitadas à zona
            de Lisboa, e mediante pagamento de uma taxa de entrega às zonas
            Cascais e Sintra, nas condições indicadas no processo de compra. Nos
            termos da lei 24/2014, uma vez que se tratam de géneros
            alimentícios, o utilizador não tem direito ao exercício da resolução
            do contrato. <br>

            <strong>1.2. </strong> Os Dados Pessoais específicos que serão
            tratados e a forma como vão ser utilizados dependem em grande medida
            dos serviços por si solicitados e consigo acordados. A presente
            Política de Privacidade aplica-se aos Dados Pessoais dos/as
            nossos/as Clientes, Utilizadores/as do nosso website, Pessoal,
            Fornecedores e Candidatos/as sempre que estes sejam pessoas
            singulares. <br>
            <strong>1.3. </strong> A Mylky Nuts respeita a
            privacidade das pessoas com quem se relaciona no exercício da sua
            atividade e protege os seus Dados Pessoais. A maior parte do website
            www.mylkynuts.com pode ser navegada sem recurso à partilha de
            qualquer informação por parte do/a Utilizador(a). Este documento
            informa-o/a da utilização que a Mylky Nuts faz dos seus Dados
            Pessoais sempre que esta for necessária para disponibilizar os seus
            serviços. <br>
            <strong> 1.4. </strong> O website www.mylkynuts.com poderá
            conter, ocasionalmente, ligações, banners ou outras ligações de
            hipertexto para websites e serviços de outras sociedades, que têm as
            suas próprias políticas de privacidade, e/ou serviços prestados por
            terceiros instalados no seu dispositivo podem permitir o acesso a
            informação constante do mesmo. Recomendamos aos/às Utilizadores(as)
            do nosso website que leiam atentamente as políticas de privacidade
            de tais terceiros antes de submeter quaisquer Dados Pessoais a esses
            websites. A Mylky Nuts não tem qualquer controlo sobre o conteúdo
            desses websites, pelo que não é responsável pelo conteúdo das
            políticas de privacidade desses terceiros nem pelo tratamento que
            dos seus Dados Pessoais é feito pelos mesmos.<br>
            <strong>1.5. </strong> A Mylky Nuts reserva-se o direito de
            modificar a Política de Privacidade em função de novas exigências
            legais e/ou regulamentares, por motivos de segurança ou com a
            finalidade de adaptar a referida Política de Privacidade às
            instruções das autoridades de controlo em matéria de protecção de
            dados. <br>
            <strong>1.6.</strong> A presente Política de Privacidade
            aplica-se a qualquer utilização do website www.mylkynuts.com e a
            qualquer transacção comercial realizada no mesmo. O/A Utilizador(a)
            e/ou Cliente deve consultar, adicionalmente, os Termos e Condições
            desse website.

            <span
              >2. Que dados recolhemos, como os utilizamos e com que
              fundamento</span
            >
            <strong>2.1.</strong> A Mylky Nuts recolhe diversos tipos de Dados
            Pessoais em função das diferentes finalidades, conforme se
            explicitará infra. Em todo o caso, salientamos que não recolhemos
            intencionalmente informação pessoal de menores de 18 anos.<br>
            <strong>2.2.</strong> A Mylky Nuts recolhe dados pessoais de acordo
            com: <br><strong>a)</strong> DADOS DO/A CLIENTE: Apenas recolhemos os
            dados necessários à preparação, cumprimento e execução de qualquer
            contrato celebrado entre o/a Cliente e a Mylky Nuts, bem como para
            responder a quaisquer reclamações posteriores relativas ao mesmo,
            nomeadamente: - Dados identificativos: tais como o nome, data de
            nascimento, e dados de contacto (telefone e e-mail); os dados
            necessários para garantir uma boa experiência na utilização do
            website www.mylkynuts.com e para permitir que possa usufruir de
            todas as potencialidades e faculdades proporcionadas pelo mesmo, bem
            como, para nos ajudar a gerir os serviços que prestamos. Tal inclui
            informações como a forma como utiliza o nosso website, a frequência
            com a qual acede ao mesmo, o seu tipo de navegador, o seu endereço
            de IP, a localização a partir da qual consulta o nosso website, a
            língua que escolhe para o visualizar e as horas em que o nosso
            website é mais popular - Dados de pagamento e/ou processamento de
            encomenda: contribuinte fiscal, morada para facturação e morada para
            envio de encomenda; IBAN. <br><strong>2.3.</strong> A Mylky Nuts usa a
            informação cedida pelo/a Cliente no website www.mylkynuts.com
            exclusivamente para satisfazer os seus pedidos de produtos e
            serviços: <br><strong>a)</strong> Sempre que o/a Cliente executa um
            processo de encomenda, a Mylky Nuts recolhe os seus Dados Pessoais
            para efeitos de venda, separação e transporte dos produtos
            encomendados; <br><strong>b)</strong> Sempre que o/a Cliente solicita a
            assistência de serviço, os seus Dados Pessoais são recolhidos com o
            objectivo de lhe fornecer os serviços de apoio ao cliente
            específicos. <br><strong>2.4.</strong> Todos os serviços que incluam
            envio automático de emails (newsletters) estão salvaguardados de
            acordo com o regulamento de proteção de dados e da relação
            contratual pré-existende, sem prejuízo o Cliente, que terá sempre
            disponível uma opção para anular a subscrição.

            <span>3. Cedência de dados</span>
            <strong>3.1.</strong> No âmbito da nossa relação comercial, terá de
            fornecer os dados pessoais necessários para estabelecer e criar essa
            relação e para cumprimento das obrigações e diligências
            pré-contratuais e contratuais derivadas e ainda os que somos
            legalmente obrigados a recolher. <br><strong>3.2.</strong> Caso não nos
            disponibilize as informações e a documentação necessárias, não
            poderemos estabelecer ou continuar a relação comercial por si
            pretendida, ou dar seguimento aos pedidos que nos dirija.

            <span>4. Cookies</span>
            <strong>4.1.</strong> O website www.mylkynuts.com utiliza cookies.
            Essa informação é utilizada para verificar a utilidade e facilidade
            de utilização do website e para verificar se tem permissão de aceder
            a determinados serviços. Poderá obter mais informações sobre o que
            são “cookies” em www.allaboutcookies.com. <br><strong>4.2.</strong> Se
            o/a Utilizador(a) preferir não receber cookies quando visitar o
            website www.mylkynuts.com deve configurar o seu programa de
            navegação ("browser") para avisar antes de registar um cookie no seu
            disco rígido, e recusá-lo quando é detectada a sua presença. Também
            pode recusar todos os cookies se configurar o seu programa de
            navegação. Avisamos que pode necessitar de activar os cookies para a
            correta usabilidade do website.

            <span>5. Google Analytics</span>
            <strong>5.1. </strong> O Google Analytics é um software que compila
            dados dos/as Utilizadores(as) do website www.mylkynuts.com. O Google
            Analytics compila dados como, por exemplo, de que website veio o/a
            Utilizador(a), quanto tempo ficou no website www.mylkynuts.com , que
            tipo de computador e browser está a usar. <br><strong>5.2.</strong> As
            informações de tracking recolhidas pelo Google Analytics permitem
            que se compreenda melhor o tipo de Utilizadores(as) que visitam o
            website www.mylkynuts.com e que conteúdo estão a ler. Isso
            permite-nos tomar melhores decisões sobre o design e o conteúdo do
            nosso website. Ocasionalmente, compilaremos estatísticas agregadas
            sobre o número de visitantes que o website recebe e os browsers que
            estão sendo usados. Nenhum dado de identificação pessoal é incluído
            nesse tipo de relatório. Toda a nossa actividade cai dentro dos
            limites dos Termos de Serviço do Google Analytics.

            <span>6. Quem são os destinatários dos seus Dados Pessoais</span>
            <strong>6.1. </strong> Os Dados Pessoais não são partilhados com
            terceiros sem a sua permissão prévia e expressa.<br>
            <strong>6.2.</strong> No entanto, pode ser solicitada permissão
            prévia expressa ao/à Utilizador(a) para autorizar a Mylky Nuts a
            ceder a informação pessoal do/a Utilizador(a) a terceiros, apenas e
            quando tal se mostre necessário para fornecer o produto ou o serviço
            solicitado, sendo garantido ao/à Utilizador(a) que apenas é
            partilhada a informação mínima necessária para a satisfação do
            pedido, restringida apenas aos dados necessários para a execução das
            tarefas contratadas. <br><strong>6.3. </strong> A Mylky Nuts garante,
            ainda, que estes terceiros se obrigam a não revelar, por qualquer
            forma, os Dados Pessoais, nem a utilizá-los para fins distintos da
            prestação de serviços contratados. <br><strong>6.4. </strong> Da mesma
            forma, a Mylky Nuts poderá comunicar os seus Dados Pessoais, quando
            assim seja determinado por lei, no âmbito de um processo judicial ou
            no âmbito de investigações de atividades suspeitas.<br>
            <strong>6.5. </strong> A Mylky Nuts em nenhum caso, levará a cabo
            transferências internacionais dos seus dados para país terceiro ou
            organização internacional que se encontre fora da União Europeia.

            <span>7. Segurança dos Dados</span>
            <strong>7.1.</strong> O website www.mylkynuts.com encontra-se
            protegido por um protocolo de transferência de hipertexto seguro
            (HTTPS) que faz a encriptação dos dados fornecidos, e garante a
            segurança dos dados enviados e recebidos pelo/a Utilizador(a). A
            segurança dos dados é também garantida através de procedimentos
            físicos, eletrónicos e processuais adequados.<br>
            <strong>7.2.</strong> Os dados do/a Utilizador(a) e ou Cliente
            registados nos servidores da Mylky Nuts estão sempre protegidos por
            um login e password. Apesar de a Mylky Nuts fazer todos os esforços,
            não pode garantir a segurança da informação que é transmitida,
            designadamente por intromissão de terceiros (hackers). A fim de
            melhor garantir a sua segurança, aconselhamos ao utilizador que
            proceda regularmente à alteração da sua palavra passe de acesso ao
            website www.mylkynuts.com. <br><strong>7.3.</strong> Os Dados Pessoais
            não serão vendidos ou alugados.

            <span>8. Direitos do Utilizador</span>
            <strong>8.1.</strong> De acordo com a legislação aplicável, a Mylky
            Nuts compromete-se a respeitar a confidencialidade da sua informação
            de carácter pessoal e garantir o exercício dos seus direitos,
            nomeadamente: <br><strong>a)</strong> Direito de acesso e retificação de
            dados: em complemento ao direito de informação, poderá aceder aos
            seus Dados Pessoais que tratamos e conservamos, através da sua área
            pessoal. <br><strong>b)</strong> Direito ao esquecimento: pode
            solicitar-nos que eliminemos os seus dados, no entanto, tenha em
            consideração que este não é um direito absoluto, uma vez que podemos
            ter fundamentos legais ou outros interesses legítimos para a
            retenção dos seus Dados Pessoais. <br><strong>c)</strong> Direito de
            oposição, incluindo a marketing direto: pode optar por ser removido
            das nossas comunicações diretas de divulgação a qualquer momento.<br>
            <strong>d)</strong> Direito de apresentar uma queixa junto da CNPD:
            caso considere que o tratamento dos seus Dados Pessoais realizado
            pela Mylky Nuts viola a legislação de protecção de dados aplicável,
            poderá apresentar reclamação perante a Comissão Nacional de
            Protecção de Dados ("CNPD”). Não hesite em contactar-nos antes de
            apresentar qualquer queixa junto da CNPD.<br>
            <strong>e)</strong> Direito à portabilidade dos dados: tem o direito
            de mover, copiar ou transferir os dados da nossa base de dados para
            outra. <br><strong>8.2.</strong> Para solicitar o exercício de qualquer
            um dos direitos acima mencionados, para além das formas
            especificamente referidas nas respectivas alíneas, deverá enviar uma
            comunicação escrita para um dos seguintes endereços:<br>

            <strong>Morada: Rua de Pedrouços 67B, 1400-285</strong><br>
            <strong>email: mylkynuts@gmail.com</strong>

            <span>9. Tempo de Armazenamento</span>
            <strong>9.1.</strong> A Mylky Nuts só guarda os Dados Pessoais pelo
            tempo necessário para atingir a finalidade para a qual os mesmos
            foram recolhidos, para responder às suas necessidades ou para
            cumprir com as nossas obrigações legais.<br>
            <strong>9.2.</strong> Quando adquirir serviços e/ou produtos,
            conservaremos os seus Dados Pessoais durante a vigência da nossa
            relação contratual, incluindo eventuais reclamações que possam
            surgir, bem como durante o prazo de um ano após a cessação da mesma,
            sem prejuízo do cumprimento de obrigações legais do responsável do
            tratamento; <br><strong>9.3.</strong> Quando nos contactar para colocar
            questões, conservaremos os seus Dados Pessoais pelo período
            necessário para resolver a sua questão;<br>
            <strong>9.4.</strong> Podemos reter ainda alguns dos seus Dados
            Pessoais na medida em que seja necessário para cumprirmos as nossas
            obrigações legais, bem como para gerir ou fazer valer os nossos
            direitos, designadamente através do recurso à via judicial.<br>
            <strong>9.5.</strong> Findos os prazos de conservação acima
            referidos, os Dados Pessoais serão eliminados e/ou apagados de forma
            segura. <br>A Mylky Nuts reserva-se ao direito de complementar ou
            alterar a presente Política de Privacidade, pelo que recomendamos a
            consulta regular da mesma.
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#modalPolity {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 700px;
    }
  }
  .modal-footer {
    .btn-primary {
      height: 45px;
      width: 70px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color5);
      border: 1px solid var(--main-color5);
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color);
      text-transform: uppercase;
      margin-left: 15px;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        background: var(--main-color);
        color: var(--main-color5);
      }
    }
  }
}
.content-modal-terms {
  &-text {
    p {
      font-size: 16px;
      font-family: "Font Regular";
      color: var(--main-color4);
      margin-bottom: 0;
      span {
        text-transform: uppercase;
        font-family: "Font Bold";
        display: block;
        margin-top: 30px;
      }
      a {
        text-decoration: underline;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>