import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    product: {},    
    productView: {},    
    loading: false,
    loadingRelated: false,
    productRelated: [],
  },
  actions,
  getters,
  mutations,
};