import Orders from '@/models/Orders';

export default { 
  loading(context, value){
    context.commit('loading', value);
  },
  getListOrders(context) {
    context.dispatch('loading', true, { root: true });
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Orders.getOrders().then(function(response){
          let data = response.data.data;
          
          context.commit('setOrders', data);
          context.dispatch('loading', false, { root: true });
          context.commit('loading', false);

          resolve();
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  getOrder(context, params) {
    context.dispatch('loading', true, { root: true });
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Orders.getOrders(params).then(function(response){
          let data = response.data.data;
          
          context.commit('setOrder', data);
          context.dispatch('loading', false, { root: true });
          context.commit('loading', false);

          resolve();
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  methodPayment(context, params) {
    context.dispatch('loading', true, { root: true });
    
    return new Promise(function(resolve, reject){
        Orders.methodPayment(params).then(function(response){
          let data = response.data.data;
                    
          context.dispatch('loading', false, { root: true });

          resolve(data);          
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },  
};