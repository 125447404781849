<template>
  <!-- style="--main-color: initial" -->
  <div id="app">
    <!-- LOADING VUE -->
    <loading :active.sync="this.$store.getters.isLoading"         
        :is-full-page="true"></loading>
    <!----------------->

    <!-- DEFINE HEADER -->
    <header-one />
    <!-------------------->
    
    <main class="content-main">
      <router-view/>
    </main>

    <!-- DEFINE MENU -->
    <menu-one />
    <!-------------------->

    <!-- DEFINE FOOTER -->
    <footer-one />
    <!-------------------->

    <modal-alert :key="this.$store.getters.alert.key" v-if="this.$store.getters.alert.open"></modal-alert>
    <modal-error :key="this.$store.getters.error.key" v-if="this.$store.getters.error.open"></modal-error>

    <!---- MODAL TERMS -->
    <modal-terms />
    <!-------------------->

    <!---- MODAL MENSAGE AND ERROR PAYMENT -->
    <modal-mensage />
    <modal-error-payment />
    <!-------------------->

    <!---- MODALS -->
    <modal-faqs />
    <modal-polity />
    <modal-contacts />
    <!-------------------->

    <!-- NOTIFICATIONS -->
    <notifications group="notify" />
    <notify :key="this.$store.getters.notify.key" />
    <!-------------------->
  </div>
</template>

<style lang="scss">

@import './assets/scss/generic/mixins.scss';
@import './assets/scss/generic/fonts.scss';

:root {
  --main-color: #fff;
  --main-color2: #D9B48F;
  --main-color3: #C6AFA0;
  --main-color4: #5E4F47;
  --main-color5: #5E4F47;
  --main-color6: #947971;
}
html,
body {
  margin: 0;
  width: 100%;
  // color: #687188;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.content-main {
  margin-top: 120px;
  @media (min-width: 992px) and (max-height: 800px) {
		margin-top: 100px;
	}
  @media (max-width: 991px){
    margin-top: 100px;
  }
}
</style>
<script>
import HeaderOne from '@/components/headers/HeaderOne.vue';
import MenuOne from '@/components/menus/MenuOne.vue';
import FooterOne from '@/components/footers/FooterOne.vue';
import Loading from 'vue-loading-overlay';

//Modals Alerts
import ModalError from '@/components/modals/ModalError';
import ModalAlert from '@/components/modals/ModalAlert';
import ModalTerms from '@/components/modals/ModalTerms';
import ModalMensage from '@/components/modals/ModalMensage';
import ModalErrorPayment from '@/components/modals/ModalErrorPayment';
import ModalFaqs from '@/components/modals/ModalFaqs';
import ModalPolity from '@/components/modals/ModalPolity';
import ModalContacts from '@/components/modals/ModalContacts';
import Notify from '@/components/modals/Notify';

import {mapActions } from 'vuex';

export default {
  data() {
    return {

    }
  },
  components: {
    HeaderOne,
    FooterOne,
    MenuOne,
    Loading,
    ModalError,
    ModalAlert,
    ModalTerms,
    ModalMensage,
    ModalErrorPayment,
    ModalFaqs,
    ModalPolity,
    ModalContacts,
    Notify
  },
  methods: {
    ...mapActions('SiteConfigs', ['setConfigs'])
  },
  mounted() {
    this.setConfigs();
  }
}
</script>
