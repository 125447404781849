import Banner from '@/models/Banner';

export default {
  async listBanners(context, value) {  	
  	context.commit('loading', true);
	await Banner.getBannersActive().then(function(response){
		let data = response.data.data;
		
		context.commit('listBanners', data);		
		context.commit('loading', false);
	})
	.catch(error => context.dispatch('setError', error, { root: true }));

  },
};