export default {
  loading(state, value){
    state.loading = value;
  },
  setOrders(state, value){
    state.orders = value;
  },
  setOrder(state, value){
    state.order = value;
  }
};