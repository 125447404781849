import Vue from 'vue'
import VueRouter from 'vue-router'

import userRoutes from '@/views/User/router';
import productRoutes from '@/views/Products/router';
import cartRoutes from '@/views/Cart/router';
import ordersRoutes from '@/views/Orders/router';
import wishlistRoutes from '@/views/Wishlist/router';

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Concept from '../views/Concept.vue'
import Healthy from '../views/Healthy.vue'
import Recipes from '../views/Recipes.vue'
import RecipesIntern from '../views/RecipesIntern.vue'


import Page404 from '../views/404.vue'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },  
  {
    path: '/404',
    name: 'Page404',
    component: Page404
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/concept',
    name: 'Concept',
    component: Concept
  },
  {
    path: '/healthy',
    name: 'Healthy',
    component: Healthy
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes
  },
  {
    path: '/recipes-intern',
    name: 'RecipesIntern',
    component: RecipesIntern
  },
  {
    path: '*',
    redirect: '/404'
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

//CONCAT ROUTERS
const routes = baseRoutes.concat(userRoutes, productRoutes, cartRoutes, ordersRoutes, wishlistRoutes);

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
