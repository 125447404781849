<template>
  <div class="content-footer">
    <div class="content-footer-itens container">
      <div class="content-footer-itens-div">
        <div class="content-footer-itens-subscribe">
          <label>{{ $t('footer.subscribe_to_our_newsletter') }}</label>
          <form
            ref="form"
            id="form-newsletter"
            class="validate form-newsletter"
            @submit.prevent="handleSubmit"
            method="post"
          >
            <input
              type="hidden"
              name="captacao"
              value="form-newsletter-ecommerce"
            />
            <input
              v-if="$isMobile()"
              type="hidden"
              name="device"
              value="mobile"
            />
            <input v-else type="hidden" name="device" value="desktop" />
            <input
              type="hidden"
              name="name"
              placeholder="Nome completo"
              required="true"
              value="newsletter"
            />
            <div class="input">
              <input
                class="email"
                type="email"
                required="true"
                :placeholder="$t('footer.your_email_address')"
                name="email"
                value=""
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.667"
                height="20"
                viewBox="0 0 26.667 20"
              >
                <path
                  d="M24.167,64H2.5A2.5,2.5,0,0,0,0,66.5v15A2.5,2.5,0,0,0,2.5,84H24.167a2.5,2.5,0,0,0,2.5-2.5v-15A2.5,2.5,0,0,0,24.167,64ZM2.5,65.667H24.167A.836.836,0,0,1,25,66.5v2.156c-1.141.964-2.771,2.292-7.844,6.318-.88.7-2.615,2.38-3.823,2.359-1.208.021-2.948-1.661-3.823-2.359-5.073-4.026-6.7-5.354-7.844-6.318V66.5A.836.836,0,0,1,2.5,65.667ZM24.167,82.333H2.5a.836.836,0,0,1-.833-.833V70.823c1.187.974,3.063,2.479,6.807,5.453,1.068.854,2.953,2.734,4.859,2.724,1.9.016,3.766-1.849,4.859-2.724C21.937,73.3,23.812,71.8,25,70.823V81.5A.836.836,0,0,1,24.167,82.333Z"
                  transform="translate(0 -64)"
                  fill="#787878"
                />
              </svg>
            </div>
            <button name="subscribe" class="btn-send-email">{{ $t('footer.send') }}</button>
          </form>
        </div>
        <div class="content-footer-itens-follow" v-if="configs.url_facebook || configs.url_instagram || configs.url_twitter" >
          <p>{{ $t('footer.follow_us') }}:</p>
          <a :href="configs.url_facebook" v-if="configs.url_facebook" target="_blank" rel="noopener">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#D0D0D0"
              width="30"
              height="30"
            >
              <path
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                class=""
              ></path>
            </svg>
          </a>
          <a :href="configs.url_instagram" v-if="configs.url_instagram" target="_blank" rel="noopener">
            <svg fill="#D0D0D0" width="30" height="30" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" class=""></path></svg>
          </a>
          <a :href="configs.url_twitter" v-if="configs.url_twitter" target="_blank" rel="noopener">
           <svg fill="#D0D0D0" width="30" height="30" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" class=""></path></svg>
          </a>
        </div>
      </div>
      <div class="content-footer-itens-links">
        <!-- <div class="content-footer-itens-links-elements">
          <p>Info</p>
          <router-link tag="a" :to="{ name: 'About' }"> About Us</router-link>
          <router-link tag="a" :to="{ name: 'Concept' }"> Concept</router-link>
          <router-link tag="a" :to="{ name: 'Recipes' }"> Recipes</router-link>
          <router-link tag="a" :to="{ name: 'Healthy' }"> Healthy</router-link>
        </div> -->
        <div class="content-footer-itens-links-elements">
          <!-- <p>Our Policies</p> -->
          <!-- <a> FAQs</a> -->
          <a v-b-modal.modalFaqs> Faqs <u>|</u></a>
          <a v-b-modal.modalTerms> {{ $t('footer.terms_and_conditions') }} <u>|</u> </a>
          <a v-b-modal.modalPolity> {{ $t('footer.privacy_policy') }} <u>|</u></a>
          <!-- <a> Termos e Condições de Venda |</a> -->
          <a v-b-modal.modalContacts> {{ $t('footer.contactos') }} </a>
        </div>
        <!-- <div class="content-footer-itens-links-elements">
          <p>Order</p>
          <router-link tag="a" :to="{ name: 'cart' }"> View Cart</router-link>
          <template v-if="Object.keys(user).length == 0">
            <router-link tag="a" :to="{ name: 'Register' }">
              Register</router-link
            >
            <router-link tag="a" :to="{ name: 'Login' }"> Login</router-link>
          </template>
          <template v-else>
            <router-link tag="a" :to="{ name: 'MyAccount' }">
              My Account</router-link
            >
            <router-link tag="a" :to="{ name: 'Wishlist' }">
              Whislist</router-link
            >
          </template>
        </div> -->
        <div class="content-footer-itens-links-elements" v-if="configs.address">
          <p>{{ $t('footer.address') }}</p>
          <a> {{ $t('footer.household') }}</a>
          <a> {{ configs.address }} - {{ configs.postal_code }} </a>
          <a> {{ configs.city }} - {{ configs.state }} </a>
          <a v-if="configs.phone" :href="'tel:' + configs.phone">
            {{ $t('footer.call_us') }} <span>{{ configs.phone }}</span></a
          >
          <!-- <a> Mon-Sun: 9:00am - 9:00pm </a> -->
        </div>
      </div>
      <div class="content-footer-itens-copyright">
        <p class="text">© 2021 Mylkynuts. {{ $t('footer.all_rights_reserved') }}.</p>
        <div class="image">
          <img
            class="img-fluid mr-3"
            src="@/assets/img/mb-way.png"
            alt="logos"
            title="logos"
          />
          <img
            class="img-fluid"
            src="@/assets/img/logo-trf-3.png"
            alt="logo"
            title="logo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketingApi from "@/models/Marketing";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("User", ["user"]),
    ...mapGetters("SiteConfigs", ["configs"]),
    ...mapGetters(["modalMensageOpen"]),
  },
  methods: {
    async submit() {
      let formData = await this.$root.getAllData(this.$refs.form);

      this.$store.dispatch("loading", true);
      MarketingApi.saveNewsletter(formData)
        .then((response) => {
          this.$store.dispatch("loading", false);

          this.$bvModal.show("modalMensage");
          this.$refs.form.reset();
        })
        .catch((error) => this.$store.dispatch("setError", error));
    },
    handleSubmit() {
      if (this.$refs.form.checkValidity()) {
        this.submit();
      } else {
        this.$refs.form.reportValidity();
      }
    },
  },
};
</script>

<style lang="scss">
.content-footer {
  padding: 60px 0px;
  background: #f8f8f8;
  &-itens {
    &-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: center;
      }
    }
    &-subscribe {
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
      }
      label {
        font-size: 21px;
        font-family: "Font Bold";
        color: var(--main-color4);
        margin-bottom: 0;
      }
      .input {
        position: relative;
        margin-left: 15px;
        @media (max-width: 991px) {
          width: 100%;
          max-width: 350px;
          margin-left: 0;
        }
        input {
          height: 45px;
          width: 400px;
          border: 1px solid #ebebeb;
          padding-left: 20px;
          font-size: 16px;
          font-family: "Font Bold";
          color: var(--main-color4);
          outline: none;
          padding-right: 60px;
          @media (min-width: 992px) and (max-width: 1199px) {
            max-width: 300px;
          }
          @media (max-width: 991px) {
            width: 100%;
          }
        }
        svg {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &-follow {
      display: flex;
      align-items: center;
      p {
        font-size: 20px;
        font-family: "Font Bold";
        color: var(--main-color4);
        margin-bottom: 0;
      }
      a {
        display: block;
        padding: 0px 10px;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
    &-links {
      display: flex;
      justify-content: space-between;
      margin-bottom: 45px;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
      }
      &-elements {
        text-align: left;
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
          flex-direction: column;
          text-align: center;
          margin-bottom: 20px;
        }
        p {
          font-size: 21px;
          font-family: "Font Bold";
          color: var(--main-color4);
          margin-bottom: 18px;
        }
        a {
          display: block;
          font-size: 20px;
          font-family: "Font Light";
          color: #787878 !important;
          text-decoration: none !important;
          transition: 500ms;
          margin-right: 7px;
          @media (max-width: 991px) {
            margin-right: 0;
            u {
              display: none;
            }
          }
          span {
            font-family: "Font Bold";
          }
          &:hover,
          &:focus {
            cursor: pointer;
            color: var(--main-color4);
            text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.5);
          }
        }
      }
    }
    &-copyright {
      text-align: left;
      @media (max-width: 991px) {
        text-align: center;
      }
      img {
        max-width: 95px;
      }
      .text {
        font-size: 14px;
        font-family: "Font Light";
        color: #787878;
        margin-bottom: 40px;
      }
    }
  }
  .form-newsletter {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      width: 100%;
      justify-content: center;
    }
    @media (max-width: 576px) {
      flex-direction: column;
    }
    .btn-send-email {
      height: 45px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color5);
      border: 1px solid var(--main-color5);
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color);
      text-transform: uppercase;
      margin-left: 15px;
      transition: 500ms;
      &:hover,
      &:focus {
        cursor: pointer;
        background: var(--main-color);
        color: var(--main-color5);
      }
      @media (max-width: 576px) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
}
</style>