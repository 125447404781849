export default {
	cartOpen: state => state.cartOpen,
	cartPayload: state => state.payload, 
	cartProducts: state => state.products,
	cartLoading: state => state.loading,
	cartProductsTotal(state){
		let total = 0;
		
		if(Object.keys(state.products).length > 0 && Object.keys(state.products.itens).length > 0){			
			state.products.itens.map((item) => {
				total += item.price_total;
			});
		}

		return total;
	},
	cartProductsAmount(state){
		let total = 0;
		
		if(Object.keys(state.products).length > 0 && Object.keys(state.products.itens).length > 0){			
			state.products.itens.map((item) => {
				total += item.amount;
			});
		}

		return total;
	},
};