import store from '@/store/index.js';

export default function money (value) {	
    value = parseFloat(value);
    value = isNaN(value) ? 0 : value;

    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });

    return formatter.format(value);
}