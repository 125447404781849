<template>
	<div></div>
</template>
<script>
	export default{
		mounted(){
			this.$fire({
			  title: "Erro",
			  text: this.$store.getters.error.message,
			  type: "error",			  
			}).then(r => {
				this.$store.dispatch('setError', '')
			});
		}
	}
</script>