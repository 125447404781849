import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import filters from './filters'
import { i18n } from './i18n';

import axios from 'axios';
import OctoberApi from "vue-october-requests";

Vue.use(filters);
Vue.use(OctoberApi, { axios });

//Mixins
import mixin from './mixins';

//Plugins
import './plugins/bootstrap-vue'
import './plugins/datetime.vue'
import './plugins/moment.vue'
import './plugins/loading-vue'
import './plugins/simple-alert-vue'
import './plugins/notification-vue'
import './plugins/skeleton';
import './plugins/lazyload';
import './plugins/mobile-detection-vue';

Vue.config.productionTip = false

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('must_token');
  const visitor = document.querySelector("meta[name=visitor]").getAttribute("content");
  
  config.headers.Authorization =  token;
  config.headers['4Shop-Visitor'] =  visitor;

  return config;
});

new Vue({
  router,
  store,
  i18n,
  mixins: [mixin],
  render: h => h(App)
}).$mount('#app')
