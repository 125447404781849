import axios from 'axios';

export default {
	async login(params){
		return axios.post('/api/v1/customer/login',params);
	},
	async recoverPass(params){
		return axios.post('/api/v1/customer/recover-pass',params);
	},
	async resetPass(params){
		return axios.post('/api/v1/customer/reset-pass',params);
	},	
	async registerCreate(params){
		return axios.post('/api/v1/customer/register-create',params);
	},
	async registerUpdate(params){
		return axios.post('/api/v1/customer/register-update',params);
	},
	async addressUpdate(params){
		return axios.post('/api/v1/customer/address-update',params);
	},
	async deleteAddress(params){
		return axios.post('/api/v1/customer/address-delete',params);
	},
	async getInfoUser(){
		return axios.get('/api/v1/customer/get-info');
	},
}