import Vue from 'vue'
import Vuex from 'vuex'

//Config Default Project
import SiteConfigs from './modules/SiteConfigs';

//Import Store User
import User from '@/views/User/store';
import Product from '@/views/Products/store';
import Cart from '@/views/Cart/store';
import Order from '@/views/Orders/store';
import Wishlist from '@/views/Wishlist/store';
import ProductSection from '@/components/ProductSections/store';
import Banner from '@/components/banner/store';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuOpen: false,
    isLoading: false,
    error: {
      key: (new Date().getTime()),
      open: false,
      message: '',
    },
    alert: {
      open: false,
      message: '',
    },
    notify:{},
    errorPayment: false,
  },
  getters: {
    menuOpen(state) {
      return state.menuOpen;
    },
    isLoading: state => {
      return state.isLoading;
    }, 
    error: state => {
      return state.error;
    },
    alert: state => {
      return state.alert;
    },
    notify: state => state.notify,
    errorPayment: state => {
      return state.errorPayment;
    },
  },
  mutations: {
    setMenuOpen(state, value){
      state.menuOpen = value;
    },
    loading(state, value){
      state.isLoading = value;
    },
    setError(state, value){
      state.error = value;
    },
    setAlert(state, value){
      state.alert = value;
    },
    setNotify(state, value){
      state.notify = value;
    },
    setModalErrorPayment(state, value){
      state.errorPayment = value;
    }
  },
  actions: {
    loading({commit}, value){    
      commit('loading', value);
    },
    setNotify({commit}, value){
      let params = {
        key: (new Date().getTime()),
        group: 'notify',
        type: 'info',
        title: 'Aviso',
        text: '',
        duration: 2000,
      }; 
      
      params = {...params, ...value};
      
      commit('setNotify', params);
    },
    setAlert({commit}, {message, title, type}){
      let opts = {open: true, key: (new Date().getTime())};
      opts.message = message;
      opts.title = title;
      opts.type = type;
  
      if(opts.message !=''){
        commit('setAlert', opts);
      }else{
        opts.open = false;
        commit('setAlert', opts);
      }
    },
    setError({commit}, err){
      commit('loading', false);
  
      let message = '';
  
      if (err.response) {
        if (err.response.data.message == 'É necessário informar o método de pagamento -> 202 - 140'){
          // message = '🚨 Por favor selecione o método de pagamento.';
          commit('setModalErrorPayment', true);
          message = '';
          // return;
        } else {
          message = '🚨 '+err.response.data.message;
        }
      } else if (err.request) {
        message = '🚨 '+err.message;
      } else if(err != ''){
        message = '🚨 Ops, aconteceu algum erro desconhecido. Tente novamente em alguns minutos.';
      }
      
      if(message == ''){
        commit('setError', {open: false, message: message, key: (new Date().getTime())});  
      }else{
        commit('setError', {open: true, message: message, key: (new Date().getTime())});  
      }
    },

  },
  modules: {
    SiteConfigs,
    User,
    ProductSection,
    Banner,
    Product,
    Cart,
    Order,
    Wishlist
  }
})
