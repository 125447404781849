import axios from 'axios';

export default {
	async addProductToCart(params){
		return axios.post('/api/v1/cart/add-product-cart',params);
	},
	async getCartProducts(){
		return axios.get('/api/v1/cart/get-cart');
	},
	async updateCartItem(payload){
		return axios.post('/api/v1/cart/update-cart-item', payload);
	},
	async updateCartCheckout(payload){
		return axios.post('/api/v1/cart/update-cart-checkout', payload);
	},
	async checkout(payload){
		return axios.post('/api/v1/cart/cart-checkout', payload);
	},
	async removeItem(payload){
		return axios.post('/api/v1/cart/remove-cart-item', payload);
	},
	async addItemWishlist(payload){
		return axios.post('/api/v1/cart/add-item-wishlist', payload);
	},
	async removeItemWishlist(payload){
		return axios.post('/api/v1/cart/remove-item-wishlist', payload);
	},
}