<template>
	<div></div>
</template>
<script>
	export default{
		mounted(){
			this.$fire({
			  title: (this.$store.getters.alert.title ? this.$store.getters.alert.title : "Sucesso"),
			  text: this.$store.getters.alert.message,
			  type: (this.$store.getters.alert.type ? this.$store.getters.alert.type : "success"),			  
			}).then(r => {
				this.$store.dispatch('setAlert', {message: ''})
			});
		}
	}
</script>