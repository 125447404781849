export default {  
  productDetails(state, payload) {    
    state.product = payload;
  },
  setProductView(state, payload) {    
    state.productView = payload;
  },
  loading(state, value){
    state.loading = value;
  },
  setProductRelated(state, payload) {    
    state.productRelated = payload;
  },
  loadingRelated(state, value){
    state.loadingRelated = value;
  },
  setVariantProductView(state, value){
    state.productView = {...state.productView, ...value};
  }
};