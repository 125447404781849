import Cart from '@/models/Cart';

export default { 
  openWishlist(context, value){
    context.commit('openWishlist', value);    
    context.dispatch('setOffcanvasOpen', value, { root: true });
  },  
  addItemWishlist(context, id) {    
    if(Object.keys(context.rootGetters['User/user']).length == 0){     
      context.dispatch('setNotify', {
        text: 'Para adicionar itens na lista de desejo faça o seu login.',
        type: 'warn',
      }, {root: true});
      return;
    }
    context.dispatch('loading', true, { root: true });
    
    return new Promise(function(resolve, reject){
        Cart.addItemWishlist({ product_id: id }).then(function(response){
          let data = response.data.data;
                    
          context.dispatch('setNotify', {
            text: 'Item adicionado',
            type: 'success',
            duration: 2000,
          }, {root: true});
          
          context.commit('setWishlist', data);
          context.dispatch('loading', false, { root: true });
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
  removeItemWishlist(context, id) {    
    context.dispatch('loading', true, { root: true });
    
    return new Promise(function(resolve, reject){
        Cart.removeItemWishlist({ item_id: id }).then(function(response){
          let data = response.data.data;

          context.dispatch('setNotify', {
            text: 'Item removido',
            type: 'sucess',
          }, {root: true});
                    
          context.commit('setWishlist', data);
          context.dispatch('loading', false, { root: true });
        }).catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
};