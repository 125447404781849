export default {  
  setCartPayload(state, payload) {
    state.payload = {...state.payload, ...payload};
  },
  clearCartPayload(state) {
    state.payload = {};
  },
  loading(state, payload) {
    state.loading = payload;
  },
  setProductsCart(state, value) {
    if(!value){
      value = [];
    }
  	state.products = value;
  },
  openCartFloating(state, value){
    state.cartOpen = value;
  }
};