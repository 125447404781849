import Products from '@/models/Products';

export default {  
  async openPageProduct(context, payload) {
    let slug = '';
    if(typeof payload == 'object'){      
      slug = payload.slug_web;
    }else{
      slug = payload;
    }

    await context.dispatch('productDetails', payload);
  },  
  async setProductView(context, params){
    context.commit('setProductView', params);
    await context.dispatch('Cart/clearCartPayload', {}, {root:true});
    context.dispatch('Cart/setCartPayload', {product: params, product_id: params.id}, {root: true});   
  },  
  loading({commit}, value){
    commit('loading', value);
  },
  async productDetails(context, params) {
    context.dispatch('Cart/clearCartPayload', {}, {root: true});
  	context.commit('productDetails', {});
    context.commit('setProductView', {});    
    // context.dispatch('loading', true, { root: true });
    context.commit('loading', true);

  	Products.getProductDetails(params).then(function(response){
  		let data = response.data.data;
  		
  		context.commit('productDetails', data);
      context.dispatch('setProductView', data);
  		// context.dispatch('loading', false, { root: true });	
      context.commit('loading', false);
  	})
  	.catch(error => context.dispatch('setError', error, { root: true }));
  },
  async getProductRelated(context, params) {    
    context.commit('setProductRelated', {});        
    context.commit('loadingRelated', true);

    Products.getProductRelated(params).then(function(response){
      let data = response.data.data;
      
      context.commit('setProductRelated', data);      
      context.commit('loadingRelated', false);
    })
    .catch(error => context.dispatch('setError', error, { root: true }));
  },
  setVariantProductView(context, variant){
    let product = context.getters.productView;
    let payload = {};

    if(variant.has_stock && parseInt(variant.stock) < 1){
      return;
    }
    
    if(!('fieldOriginals' in product)){
      payload.fieldOriginals = {
        sku: product.sku,
        price: product.price,
        price_from: product.price_from,
        has_stock: product.has_stock,
        stock: product.stock
      }
    }
    
    if(variant.sku){
      payload.sku = variant.sku;
    }else if(product.fieldOriginals){
      payload.sku = product.fieldOriginals.sku;
    }

    if(variant.has_stock){
      payload.has_stock = variant.has_stock;
      payload.stock = variant.stock;
    }else if(product.fieldOriginals){
      payload.has_stock = product.fieldOriginals.has_stock;
      payload.stock = product.fieldOriginals.stock;
    }
    
    if(variant.price){
      payload.price = variant.price;
      payload.price_from = variant.price_from;
    }else if(product.fieldOriginals){
      payload.price = product.fieldOriginals.price;
      payload.price_from = product.fieldOriginals.price_from;
    }

    if(variant.size_variant_id){
      context.dispatch('Cart/setCartPayload', {variant_size_id: variant.id}, {root: true});
    }else if(variant.color_variant_id){
      context.dispatch('Cart/setCartPayload', {variant_color_id: variant.id}, {root: true});
    }

    context.commit('setVariantProductView', payload);
  },
  async addRatingProduct(context, params) {
    context.commit('loading', true, {root: true});

    return new Promise(function(resolve, reject){
      Products.addRatingProduct(params).then(function(response){        
        let data = response.data;
                
        context.commit('loading', false, {root: true});
        resolve(data);
      })
      .catch(error => context.dispatch('setError', error, { root: true }));
    });
  },
};