import {protectedPage} from '@/utils';

export default [
  {
    path: '/carrinho-compra',
    name: 'cart',
    component: () => import(/* webpackChunkName: "cart" */'@/views/Cart/views/Index.vue'),
    // children: [
    //   {
    //     path: '/checkout',
    //     name: 'cart-checkout',
    //     beforeEnter(to, from, next){      
    //       protectedPage(to, from, next);
    //     },
    //     component: () => import(/* webpackChunkName: "cart-checkout" */'@/views/Cart/views/Checkout'),
    //   },
    // ]
  },
  {
    path: '/checkout',
    name: 'cart-checkout',
    // beforeEnter(to, from, next){      
    //   protectedPage(to, from, next);
    // },
    component: () => import(/* webpackChunkName: "cart-checkout" */'@/views/Cart/views/Checkout/Index.vue'),
  },  
];