<template>
  <section class="content-concept">
    <div class="content-concept-itens container">
      <router-link tag="a" class="btn-return" :to="{ name: 'Home' }">
        <img
          class="img-fluid"
          src="@/assets/img/arrow-left.png"
          alt="arrow left"
          title="arrow left"
        />
        {{ $t('btns_default.return') }}
      </router-link>
      <div class="content-concept-itens-header">
        <h1>{{ $t('home.concept') }}</h1>
      </div>
      <div class="content-concept-itens-body">
        <div class="content-elements">
          <div class="row content-elements-rows">
            <div class="col-lg-6">
              <div class="content-elements-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/concept/image-1.jpg"
                  alt="image 1"
                  title="image 1"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-elements-text">
                <h2>Title</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus interdum nisi convallis interdum.
                  Nunc sit amet diam vehicula, ullamcorper elit non, volutpat
                  erat. Curabitur pharetra dictum efficitur. Quisque accumsan
                  est nec volutpat tristique. Nam consequat convallis lorem,
                  vitae interdum tellus faucibus id. Suspendisse vitae arcu
                  eros. Quisque elit purus, dapibus at ornare a, semper non
                  neque.
                </p>
                <p>
                  Curabitur vel velit at massa cursus aliquam sit amet eget
                  dolor. In non leo quis elit dapibus aliquam. Proin risus odio,
                  vestibulum non augue eget, porttitor laoreet mi. Cras mi odio,
                  blandit non arcu vel, tristique elementum leo. Suspendisse
                  potenti. Sed vitae sapien ut lorem egestas pharetra non sed
                  turpis. In vel risus odio. Vestibulum rhoncus nulla id purus
                  pulvinar, sed tempus sapien ultricies. Integer dignissim,
                  velit mollis dictum semper, ipsum sapien vehicula dolor, a
                  gravida orci nisl id nulla. Nulla facilisi. Maecenas euismod
                  ornare orci, nec molestie velit tristique tincidunt.
                </p>
              </div>
            </div>
          </div>
          <div class="row content-elements-rows">
            <div class="col-lg-6">
              <div class="content-elements-image">
                <img
                  class="img-fluid"
                  src="@/assets/img/concept/image-2.jpg"
                  alt="image 1"
                  title="image 1"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-elements-text">
                <h2>Title</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus interdum nisi convallis interdum.
                  Nunc sit amet diam vehicula, ullamcorper elit non, volutpat
                  erat. Curabitur pharetra dictum efficitur. Quisque accumsan
                  est nec volutpat tristique. Nam consequat convallis lorem,
                  vitae interdum tellus faucibus id. Suspendisse vitae arcu
                  eros. Quisque elit purus, dapibus at ornare a, semper non
                  neque.
                </p>
                <p>
                  Curabitur vel velit at massa cursus aliquam sit amet eget
                  dolor. In non leo quis elit dapibus aliquam. Proin risus odio,
                  vestibulum non augue eget, porttitor laoreet mi. Cras mi odio,
                  blandit non arcu vel, tristique elementum leo. Suspendisse
                  potenti. Sed vitae sapien ut lorem egestas pharetra non sed
                  turpis. In vel risus odio. Vestibulum rhoncus nulla id purus
                  pulvinar, sed tempus sapien ultricies. Integer dignissim,
                  velit mollis dictum semper, ipsum sapien vehicula dolor, a
                  gravida orci nisl id nulla. Nulla facilisi. Maecenas euismod
                  ornare orci, nec molestie velit tristique tincidunt.
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <div class="content-elements-text">
                <h2>Title</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse sagittis lectus interdum nisi convallis interdum.
                  Nunc sit amet diam vehicula, ullamcorper elit non, volutpat
                  erat. Curabitur pharetra dictum efficitur. Quisque accumsan
                  est nec volutpat tristique. Nam consequat convallis lorem,
                  vitae interdum tellus faucibus id. Suspendisse vitae arcu
                  eros. Quisque elit purus, dapibus at ornare a, semper non
                  neque.
                </p>
                <p>
                  Curabitur vel velit at massa cursus aliquam sit amet eget
                  dolor. In non leo quis elit dapibus aliquam. Proin risus odio,
                  vestibulum non augue eget, porttitor laoreet mi. Cras mi odio,
                  blandit non arcu vel, tristique elementum leo. Suspendisse
                  potenti. Sed vitae sapien ut lorem egestas pharetra non sed
                  turpis. In vel risus odio. Vestibulum rhoncus nulla id purus
                  pulvinar, sed tempus sapien ultricies. Integer dignissim,
                  velit mollis dictum semper, ipsum sapien vehicula dolor, a
                  gravida orci nisl id nulla. Nulla facilisi. Maecenas euismod
                  ornare orci, nec molestie velit tristique tincidunt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.btn-return {
  display: flex;
  align-items: center;
  background: 0;
  border: 0;
  font-size: 16px;
  font-family: "Font Bold";
  color: var(--main-color4) !important;
  padding: 0;
  text-decoration: none;
  transition: 500ms;
  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: none;
  }
  img {
    margin-right: 5px;
  }
}
.content-concept {
  padding: 70px 0px;
	@media (max-width: 991px){
		padding: 30px 0px;
	}
  &-itens {
    &-header {
      margin-bottom: 90px;
      @media (max-width: 991px) {
				margin-top: 30px;
        margin-bottom: 30px;
      }
      h1 {
        font-size: 48px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 991px) {
          font-size: 32px;
        }
      }
    }
    &-body {
      .content-elements {
        &-rows {
          &:nth-child(even){
            flex-direction: row-reverse;
            @media (max-width: 991px){
              flex-direction: column-reverse;
            }
          }
          @media (max-width: 991px){
            flex-direction: column-reverse;
          }
        }
        &-image {
          position: relative;
          padding-top: 80px;
          transition: 500ms;
          overflow: hidden;
          @media (max-width: 991px){
            padding-top: 0;
            margin: 30px 0px;
          }
          &:hover,&:focus {
            transform: scale(1.02);
            opacity: 0.8;
          }
        }
        &-text {
          text-align: left;
          h2 {
            font-size: 45px;
            font-family: "Font Bold";
            color: var(--main-color4);
            margin-bottom: 20px;
            @media (max-width: 991px) {
              font-size: 32px;
            }
          }
          p {
            font-size: 20px;
            font-family: "Font Light";
            color: #a7a9a7;
            @media (min-width: 992px) and (max-width: 1199px){
              font-size: 18px;
            }
            @media (max-width: 991px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>