import SiteConfigs from '@/models/SiteConfigs';

/***
Configurações do site
**/
export default {
	namespaced: true,

	state: {	
		configs: {},
		visitor: document.querySelector("meta[name=visitor]").getAttribute("content"),
	},

	getters: {
		configs(state){
			return state.configs;
		},		
	},

	mutations: {
		setConfigs(state, value){
			state.configs = value;			
		},
	},

	actions: {
		setConfigs(context, value){			
			return new Promise(function(resolve, reject){				
				context.dispatch('loading', true, { root: true });

				SiteConfigs.getConfigs().then(function(response){
					let data = response.data.data;
					
					context.commit('setConfigs', data);
					context.dispatch('Cart/getCartProducts', {}, {root: true});
					
					if(localStorage.getItem('must_token')){
						context.dispatch('User/getUser', {}, {root: true});
					}
					
					context.dispatch('loading', false, { root: true });

					resolve();
				})
				.catch(error => context.dispatch('setError', error, { root: true }));
			});	
		},
	}
}
