<template>
  <header class="content-header-one">
    <div class="content-header-one-itens container">
      <div class="content-header-one-itens-div">
        <router-link tag="a" :to="{name: 'Home' }" class="content-header-one-itens-logo">
          <img
            v-if="configs.logo_path"
            class="img-fluid"
            v-lazy="configs.logo_path"
            alt="logo company"
            title="logo company"
          />
          <!-- <img
            v-else
            class="img-fluid"
            src="@/assets/img/logo-company.png"
            alt="logo company"
            title="logo company"
          /> -->
        </router-link>
        <div class="content-menu d-lg-none">
          <a class="content-menu-item btn-menu" @click="menuOpen(true)">
            <span></span>
            <p>{{ $t('menu.menu') }}</p>
          </a>
        </div>
      </div>
      <!-- <div class="content-header-one-itens-search">
        <input type="text" name="search" value="" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32.4"
          height="32.408"
          viewBox="0 0 32.4 32.408"
        >
          <path
            d="M36.52,34.556l-9.011-9.1a12.842,12.842,0,1,0-1.949,1.974l8.952,9.037a1.387,1.387,0,0,0,1.957.051A1.4,1.4,0,0,0,36.52,34.556Zm-19.1-7.012a10.14,10.14,0,1,1,7.172-2.97A10.078,10.078,0,0,1,17.418,27.544Z"
            transform="translate(-4.5 -4.493)"
          />
        </svg>
      </div> -->
      <div class="content-header-one-itens-links d-none d-lg-flex">
        <template v-if="Object.keys(user).length == 0">
          <router-link tag="a" :to="{name: 'Register' }" class="links">
            <div class="links-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.487"
                height="29.498"
                viewBox="0 0 24.487 29.498"
              >
                <g transform="translate(0 0)">
                  <g transform="translate(5.306)">
                    <path
                      d="M3764.081,328.142a3.316,3.316,0,0,0-3.5.94c-.545.316-1.261.42-1.7.863s-.547,1.159-.863,1.7c-1.066,1.208-1.072,5.8,0,7.008.315.545.42,1.261.863,1.7s1.159.547,1.7.863a3.316,3.316,0,0,0,3.5.94c1.6.314,4.765-1.36,5.208-1.8s.547-1.159.863-1.7a7,7,0,0,0,0-7.008c-.315-.545-.42-1.261-.863-1.7s-1.159-.547-1.7-.863A3.316,3.316,0,0,0,3764.081,328.142Zm0,12.582c-.482,0-.9-.415-1.349-.535a5.235,5.235,0,0,1-3.687-3.687,10.2,10.2,0,0,1,0-2.7,5.236,5.236,0,0,1,3.688-3.687,10.2,10.2,0,0,1,2.7,0,5.235,5.235,0,0,1,3.687,3.687c.12.445.535.867.535,1.349s-.415.9-.535,1.349a5.235,5.235,0,0,1-3.687,3.687C3764.985,340.309,3764.563,340.724,3764.081,340.724Z"
                      transform="translate(-3757.208 -328.002)"
                    />
                  </g>
                  <g transform="translate(0 16.241)">
                    <path
                      d="M3580.514,906.214a12.719,12.719,0,0,0-3.225.065,12.508,12.508,0,0,0-8.813,8.813,18.481,18.481,0,0,0-.065,3.824c2.7.966,8.758-.226,12.1.479,4.325-.693,7.951.354,12.276-.358a17.609,17.609,0,0,0-.237-3.945,12.508,12.508,0,0,0-8.813-8.813A12.741,12.741,0,0,0,3580.514,906.214Zm-10.491,11.378a29.45,29.45,0,0,1,1.579-5.124,10.858,10.858,0,0,1,1.948-1.741,5.676,5.676,0,0,1,4.441-2.4,5.088,5.088,0,0,1,5.049,0,5.675,5.675,0,0,1,4.439,2.4,10.844,10.844,0,0,1,1.948,1.741,29.489,29.489,0,0,1,1.579,5.124c-1.687.435-1.786-.352-3.527-.352a21.394,21.394,0,0,1-6.964,0c-1.741,0-1.741.358-3.482.358s-1.773-.554-3.5-.356C3571.754,917.444,3571.708,918.027,3570.022,917.592Z"
                      transform="translate(-3568.335 -906.138)"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p class="links-text"> {{ $t('header.register') }}</p>
          </router-link>
          <router-link tabindex="a" :to="{name: 'Login' }" class="links">
            <div class="links-icon">
              <img class="img-fluid links-icon-image-1" src="@/assets/img/header/login_02.png" alt="image login" title="image login" />
              <img class="img-fluid links-icon-image-2" src="@/assets/img/header/login_01.png" alt="image login" title="image login" />
            </div>
            <p class="links-text"> {{ $t('header.login') }} </p>
          </router-link>
          <router-link tag="a" :to="{name: 'cart' }" class="links">
            <div class="links-icon">
              <img class="img-fluid links-icon-image-1" src="@/assets/img/header/basket_02.png" alt="image cesto" title="image cesto" />
              <img class="img-fluid links-icon-image-2" src="@/assets/img/header/basket_01.png" alt="image cesto" title="image cesto" />
            </div>
            <p class="links-text"> {{ $t('header.cart') }}</p>
            <span class="cart-bag ">
              <template v-if="(Object.keys(cartProducts).length > 0)">
                {{ cartProductsAmount }}
              </template>
              <template v-else>
                0
              </template>
            </span>
          </router-link>
        </template>
        <template v-else>
          <router-link tag="a" :to="{name: 'MyAccount' }" class="links">
            <div class="links-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.487"
                height="29.498"
                viewBox="0 0 24.487 29.498"
              >
                <g transform="translate(0 0)">
                  <g transform="translate(5.306)">
                    <path
                      d="M3764.081,328.142a3.316,3.316,0,0,0-3.5.94c-.545.316-1.261.42-1.7.863s-.547,1.159-.863,1.7c-1.066,1.208-1.072,5.8,0,7.008.315.545.42,1.261.863,1.7s1.159.547,1.7.863a3.316,3.316,0,0,0,3.5.94c1.6.314,4.765-1.36,5.208-1.8s.547-1.159.863-1.7a7,7,0,0,0,0-7.008c-.315-.545-.42-1.261-.863-1.7s-1.159-.547-1.7-.863A3.316,3.316,0,0,0,3764.081,328.142Zm0,12.582c-.482,0-.9-.415-1.349-.535a5.235,5.235,0,0,1-3.687-3.687,10.2,10.2,0,0,1,0-2.7,5.236,5.236,0,0,1,3.688-3.687,10.2,10.2,0,0,1,2.7,0,5.235,5.235,0,0,1,3.687,3.687c.12.445.535.867.535,1.349s-.415.9-.535,1.349a5.235,5.235,0,0,1-3.687,3.687C3764.985,340.309,3764.563,340.724,3764.081,340.724Z"
                      transform="translate(-3757.208 -328.002)"
                    />
                  </g>
                  <g transform="translate(0 16.241)">
                    <path
                      d="M3580.514,906.214a12.719,12.719,0,0,0-3.225.065,12.508,12.508,0,0,0-8.813,8.813,18.481,18.481,0,0,0-.065,3.824c2.7.966,8.758-.226,12.1.479,4.325-.693,7.951.354,12.276-.358a17.609,17.609,0,0,0-.237-3.945,12.508,12.508,0,0,0-8.813-8.813A12.741,12.741,0,0,0,3580.514,906.214Zm-10.491,11.378a29.45,29.45,0,0,1,1.579-5.124,10.858,10.858,0,0,1,1.948-1.741,5.676,5.676,0,0,1,4.441-2.4,5.088,5.088,0,0,1,5.049,0,5.675,5.675,0,0,1,4.439,2.4,10.844,10.844,0,0,1,1.948,1.741,29.489,29.489,0,0,1,1.579,5.124c-1.687.435-1.786-.352-3.527-.352a21.394,21.394,0,0,1-6.964,0c-1.741,0-1.741.358-3.482.358s-1.773-.554-3.5-.356C3571.754,917.444,3571.708,918.027,3570.022,917.592Z"
                      transform="translate(-3568.335 -906.138)"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p class="links-text"> {{ $t('header.my_account') }}</p>
          </router-link>
          <router-link tag="a" :to="{name: 'Wishlist' }" class="links">
            <div class="links-icon">
              <img class="img-fluid links-icon-image-1" src="@/assets/img/header/fav_02.png" alt="image favoritos" title="image favoritos" />
              <img class="img-fluid links-icon-image-2" src="@/assets/img/header/fav_01.png" alt="image favoritos" title="image favoritos" />
            </div>
            <p class="links-text"> {{ $t('header.wishlist') }} </p>
            <span class="cart-bag">
              <template v-if="(Object.keys(wishlistProducts).length > 0)">
                {{ wishlistProducts.length }}
              </template>
              <template v-else>
                0
              </template>
            </span>
          </router-link>
          <router-link tag="a" :to="{name: 'cart' }" class="links">
            <div class="links-icon">
              <img class="img-fluid links-icon-image-1" src="@/assets/img/header/basket_02.png" alt="image cesto" title="image cesto" />
              <img class="img-fluid links-icon-image-2" src="@/assets/img/header/basket_01.png" alt="image cesto" title="image cesto" />
            </div>
            <p class="links-text">
              {{ $t('header.cart') }}
            </p>
            <span class="cart-bag">
              <template v-if="(Object.keys(cartProducts).length > 0)">
                {{ cartProductsAmount }}
              </template>
              <template v-else>
                0
              </template>
            </span>
          </router-link>
          <a class="links" @click="logoff()">
            <div class="links-icon">
              <img class="img-fluid links-icon-image-1" src="@/assets/img/header/logout_02.png" alt="image logout" title="image logout" />
              <img class="img-fluid links-icon-image-2" src="@/assets/img/header/logout_01.png" alt="image logout" title="image logout" />
            </div>
            <p class="links-text">
              {{ $t('header.logoff') }}
            </p>
          </a>
        </template>
      </div>
      <!-- <div class="content-header-one-itens-language d-none d-lg-flex">
        <a class="active">
          Eng
          <img
            class="img-fluid"
            src="@/assets/img/arrow-down.png"
            alt="icon arrow down"
            title="icon arrow down"
          />
        </a>
        <div class="content-header-one-itens-language-menu">
          <a> Português </a>
          <a> Espanhol </a>
        </div>
      </div> -->
    </div>
  </header>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("User", ["user"]),
    ...mapGetters('Cart', ['cartOpen', 'cartProducts', 'cartProductsTotal','cartProductsAmount']),
    ...mapGetters('Wishlist', ['wishlistProducts']),
    ...mapGetters('SiteConfigs', ['configs']),
  },
  methods: {
    ...mapMutations({menuOpen: "setMenuOpen",}),
    ...mapActions("User", {userLogoff: "logoff",}),
    ...mapActions('Cart', ['openCartFloating']),

    logoff() {
      this.userLogoff();
      if(this.$route.name != 'Home'){
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style lang="scss">
.content-header-one {
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--main-color5);
  height: 120px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @media (min-width: 992px) and (max-height: 800px) {
    height: 100px;
  }
  @media (max-width: 991px) {
    height: 100px;
    background: var(--main-color5);
    box-shadow: 0px 1px 3px rgb(0 0 0 / 16%);
  }
  &-itens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    &-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0px;
      @media (min-width: 992px) {
        width: initial;
      }
    }
    // &-logo {
    //   img {
    //     @media (max-width: 991px) {
    //       max-width: 60px;
    //     }
    //   }
    // }
    &-search {
      position: relative;
      @media (max-width: 991px) {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      input {
        height: 60px;
        width: 400px;
        border-radius: 36px;
        border: 2px solid rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        padding-right: 70px;
        color: #000;
        outline: none;
        font-size: 20px;
        @media (min-width: 992px) and (max-width: 1199px) {
          height: 50px;
          width: 300px;
        }
        @media (max-width: 991px) {
          width: 100%;
          height: 40px;
          border-radius: 0px;
          border: 0;
          padding-right: 45px;
        }
      }
      svg {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        fill: rgba(0, 0, 0, 0.2);
        @media (max-width: 991px) {
          max-width: 20px;
          right: 15px;
        }
      }
    }
    &-links {
      display: flex;
      align-items: center;
      .links {
        margin-right: 30px;
        text-decoration: none;
        position: relative;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          .links-icon {
            background: var(--main-color5);
            svg {
              fill: var(--main-color);
            }
            &-image-1 {
              display: block;
            }
            &-image-2 {
              display: none;
            }
          }
          .links-text {
            opacity: 0.8;
          }
        }
        &-icon {
          height: 56px;
          width: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--main-color);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 100%;
          margin: 0 auto;
          transition: 500ms;
          svg {
            fill: var(--main-color6);
            max-width: 30px;
            transition: 500ms;
          }
          &-image-1,&-image-2{
            max-width: 33px;
          }
          &-image-1 {
            display: none;
          }
        }
        &-text {
          font-size: 16px;
          font-family: 'Font Light';
          color: var(--main-color);
          margin-bottom: 0;
          transition: 500ms;
        }
        &:last-child {
          margin-right: 0;
        }
        .cart-bag {
          position: absolute;
          top: -6px;
          right: 0;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          background: var(--main-color);
          color: var(--main-color5);
          font-size: 12px;
          font-family: 'Font Light';
          box-shadow: 0px 2px 6px rgb(0 0 0 / 16%);
          border-radius: 100%;
        } 
      }
    }
    &-language {
      position: relative;
      &:hover,
      &:focus {
        .active {
          text-decoration: underline;
        }
        .content-header-one-itens-language-menu {
          opacity: 1;
          pointer-events: initial;
        }
      }
      .active {
        font-size: 16px;
        color: #333333 !important;
        font-family: "Font Light";
        transition: 500ms;
        display: flex;
        align-items: center;
        img {
          margin-left: 10px;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      &-menu {
        position: absolute;
        top: 100%;
        transition: 500ms;
        opacity: 0;
        pointer-events: none;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        a {
          font-size: 16px;
          color: var(--main-color) !important;
          transition: 500ms;
          font-family: "Font Light";
          background: var(--main-color5);
          padding: 10px 15px;
          display: block;
          &:hover,
          &:focus {
            cursor: pointer;
            color: var(--main-color5) !important;
            background: var(--main-color);
          }
        }
      }
    }
  }
}
.content-menu {
  &-item {
    width: 32px;
    display: block;
    padding-top: 10px;
    text-decoration: none !important;
    &:hover,
    &:focus {
      cursor: pointer;
    }
    span {
      width: 100%;
      height: 3px;
      background-color: var(--main-color);
      display: block;
      position: relative;
      transform-origin: center;
      transition: 0.5s ease-in-out;
      margin-bottom: 5px;
      margin-right: 30px;
      &::before {
        content: "";
        transition: 0.5s ease-in-out;
        top: -9px;
        display: block;
        background-color: var(--main-color);
        position: absolute;
        height: 100%;
        width: 100%;
      }
      &::after {
        content: "";
        transition: 0.5s ease-in-out;
        bottom: -9px;
        display: block;
        background-color: var(--main-color);
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
    p {
      font-size: 10px;
      color: var(--main-color);
      font-family: "Font Light";
      margin-bottom: 0px;
      margin-top: 12px;
      text-align: center;
    }
    .active {
      background: 0;
      &::before {
        content: "";
        top: 0 !important;
        transform: rotate(45deg) !important;
      }
      &::after {
        content: "";
        bottom: 0 !important;
        transform: rotate(-45deg) !important;
      }
    }
  }
}
</style>