<template>
	<div>
		<swiper-products></swiper-products>
	</div>
</template>
<script>    
    import SwiperProducts from './SwiperProducts';
    import {mapGetters, mapActions} from 'vuex';

	export default{
		props: {
			options: [Object, Array],
		},
        components: {
            SwiperProducts,            
        },
        methods: {
            ...mapActions('ProductSection', ['setProductSections'])
        },
        async mounted() {
            let response = await this.setProductSections(this.options); 
                       
        },
	}
</script>