import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {    
    orders: [],
    loading: false,
    order: {},
  },
  actions,
  getters,
  mutations,
};